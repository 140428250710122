import _ from "lodash";
import { getCustomerOrders } from "./../services/Orders";
import logger from "../logger";

async function getLastOrder(userName: string) {
    try {
        return await getCustomerOrders(userName)
            .then((customerOrders) => {
                if (customerOrders && !_.isEmpty(customerOrders)) {
                    const sortedOrders = _.orderBy(customerOrders, [(order) => new Date(order.createdAt)], ["asc"]);
                    const lastOrder = _.last(sortedOrders);
                    return lastOrder;
                } else {
                    //alert('Customer list is empty: ' + customerOrders);
                    logger.debug("Customer list is empty: " + customerOrders);
                    //console.log('Customer list is empty: ' + customerOrders)
                }
            })
            .catch((err) => {
                alert("Error while getting latest order: " + err);
            });
    } catch (error) {
        logger.error(error);
        //console.log(error);
        alert(error);
    }
}

export { getLastOrder };
