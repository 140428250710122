//Set dev ids
//TODO Need to be moved somewhere else...
const PRICE_IDS_DEV = {
    "aava-pcr": "price_1KUBtSDoHTi609zMFP32CfzS", //PCR test
    "aava-antigen": "price_1KUBu5DoHTi609zMkqfcgCjP", //Antigen test
    "aava-pcr-rapid": "price_1KRDyNDoHTi609zMTKA9t087", //PCR Rapid test
    "aava-pcr-rapid-chn": "price_1KRDyNDoHTi609zMTKA9t087", //PCR Rapid test
    "aava-antigen-family": "price_1KfkB2DoHTi609zMCN1sksfa", //Antigen family price
    "ninelives-pcr": "price_1LDxbxDoHTi609zMbqdM0GzG", //PCR test
    "ninelives-pcr-jpn": "price_1LPo7tDoHTi609zMsAAKV3tR", //PCR test
    "ninelives-antigen": "price_1LDxSqDoHTi609zM0oLp9aS8", //Antigen test
    "ninelives-antigen-home-oulu": "price_1LDxSqDoHTi609zM0oLp9aS8",
    "ninelives-pcr-campaingn": "price_1LDxhVDoHTi609zMOmsg0jnl", //PCR Rapid test
    "ninelives-home-helsinki": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-home-poh": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-home-tampere": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-pcr-home-oulu": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-home-turku": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-home-lahti": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-vaccine-tick-adult": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-vaccine-tick-kids": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-influenza-vaccine-en": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "ninelives-influenza-vaccine-fi": "price_1LDxhVDoHTi609zMOmsg0jnl",
    "medipulssi-pcr": "price_1LDthxDoHTi609zMnvIfczgn", //PCR test
    "medipulssi-antigen": "price_1LDramDoHTi609zMqOEAhIBV", //Antigen test
};

//Set prod ids
//TODO Need to be moved somewhere else...
const PRICE_IDS_PROD = {
    "aava-pcr": "price_1KjKkkDoHTi609zM2NiZky4l", //PCR test
    "aava-antigen": "price_1LHmQ0DoHTi609zMWseTtAeH", //Antigen test
    "aava-pcr-rapid": "price_1KjKoLDoHTi609zMGce5ids6", //PCR Rapid test
    "aava-pcr-rapid-chn": "price_1KjKoLDoHTi609zMGce5ids6", //PCR Rapid test
    "aava-antigen-family": "price_1KjKnnDoHTi609zM1uliwL82", //Antigen family price
    "ninelives-pcr": "price_1LHkwuDoHTi609zMKasuVjo6",
    "ninelives-pcr-jpn": "price_1LQdnSDoHTi609zMQzaZFEXg",
    "ninelives-antigen": "price_1LHnImDoHTi609zMKUElAzyi",
    "ninelives-antigen-home-oulu": "price_1LKedSDoHTi609zMxOZuV7pN",
    "ninelives-pcr-campaingn": "price_1LHnU9DoHTi609zMDIqH6OrZ",
    "ninelives-home-helsinki": "price_1LKgPpDoHTi609zMq0mp5cvy",
    "ninelives-home-poh": "price_1LKgBSDoHTi609zMCWXhYxP7",
    "ninelives-home-tampere": "price_1LKgHxDoHTi609zMHJ2S3JkO",
    "ninelives-pcr-home-oulu": "price_1LKg8pDoHTi609zML3q0jE5K",
    "ninelives-home-turku": "price_1LKgKDDoHTi609zMIMcqrZjS",
    "ninelives-home-lahti": "price_1LKgElDoHTi609zM9OqvDzrR",
    "ninelives-vaccine-tick-adult": "price_1LMmQXDoHTi609zMlflnsfgj",
    "ninelives-vaccine-tick-kids": "price_1LMmcxDoHTi609zM7EPzx16h",
    "ninelives-influenza-vaccine-en": "price_1Lx6V7DoHTi609zMrG3njeq9",
    "ninelives-influenza-vaccine-fi": "price_1Lx4qgDoHTi609zMQQwBI16z",
    "ninelives-vaccine-influenza-and-pneumococcal-fi": "price_1MTkFUDoHTi609zMG1MNmYc2",
    "ninelives-vaccine-influenza-and-pneumococcal-en": "price_1MTkTVDoHTi609zMpBVJLgcP",
    "ninelives-vaccine-hepatitis-a-adults-fi": "price_1MTkZdDoHTi609zMmw2soMg5",
    "ninelives-vaccine-hepatitis-a-adults-en": "price_1MTkajDoHTi609zMLnNMawRi",
    "ninelives-vaccine-hepatitis-b-adults-fi": "price_1MTkhnDoHTi609zMkNiF2Ghs",
    "ninelives-vaccine-hepatitis-b-adults-en": "price_1MTkjKDoHTi609zMaAFk2uDa",
    "ninelives-vaccine-hepatitis-ab-adults-fi": "price_1MTkoeDoHTi609zMh1fUdMSG",
    "ninelives-vaccine-hepatitis-ab-adults-en": "price_1MTkpiDoHTi609zMwlooY3Pn",
    "ninelives-vaccine-shingles-fi": "price_1MTktxDoHTi609zMNAtJUiEn",
    "ninelives-vaccine-shingles-en": "price_1MTkv5DoHTi609zMUQ7SYESJ",
    "ninelives-vaccine-hepatitis-ab-children-fi": "price_1MU6XyDoHTi609zM1VlLNgUf",
    "ninelives-vaccine-hepatitis-ab-children-en": "price_1MU6a3DoHTi609zMbKtbe8ON",
    "ninelives-vaccine-hepatitis-a-children-fi": "price_1MU6ctDoHTi609zMxXf3Imyr",
    "ninelives-vaccine-hepatitis-a-children-en": "price_1MU6gwDoHTi609zMFSyI73f6",
    "ninelives-vaccine-pneumococcal-fi": "price_1MU6iYDoHTi609zMDmjsqPWt",
    "ninelives-vaccine-pneumococcal-en": "price_1MU6jODoHTi609zMBlJhvKeC",
    "medipulssi-pcr": "price_1LHnn2DoHTi609zMomgl1yEt", //PCR test
    "medipulssi-antigen": "price_1LHnoDDoHTi609zMEGKRe38x", //Antigen test
};

export const getPriceId = (testType: string) => {
    if (process.env.REACT_APP_ENV == "prod") {
        return PRICE_IDS_PROD[testType];
    } else {
        return PRICE_IDS_DEV[testType];
    }
};

export const getproductlistinfo = (productlist, producttype) => {
    //find the object and return it
    const productfilteredlist = productlist.filter((obj) => {
        return obj.producttype === producttype;
    });
    return productfilteredlist[0];
};
