import _ from "lodash";

//TODO: numberformat locales needed?
//Change static values to dynamic
export const formatPrice = ({ amount, currency }) => {
    const currencyValue = currency ? currency : "EUR";
    const locale = "en-GB";
    const numberFormat = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyValue,
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });
    return numberFormat.format(amount);
};

export const formatTotalPrice = ({ amount, currency, quantity }) => {
    const currencyValue = currency ? currency : "EUR";
    const locale = "en-GB";
    const numberFormat = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyValue,
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });
    const total = _.toNumber((quantity * amount).toFixed(2));
    return numberFormat.format(total);
};
