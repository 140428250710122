import { useContext, useMemo, useState } from "react";
import COMPONENTS from "../components/componentMapper";
import { IOrderData } from "../types/Interfaces";
import { useTranslation } from "react-i18next";
import { OrderDataContext } from "../context/Config";
import { Route, Routes, useNavigate } from "react-router-dom";

import {
    Authenticator,
    Button,
    Heading,
    Image,
    Text,
    translations,
    useAuthenticator,
    useTheme,
    View,
} from "@aws-amplify/ui-react";
import { Auth, Hub, I18n } from "aws-amplify";
import { getLastOrder } from "./OrdersUtils";
import SpinLoader from "../components/Loaders/SpinLoader";
import { privateRoutes } from "./routes";
import logger from "../logger";
import T2TLogo from "../assets/img/test2travel-logo.svg";
import H2TLogo from "../assets/img/Health4Travel-logo.png";

I18n.putVocabularies(translations);

I18n.putVocabularies({
    fi: {
        "Sign In": "Kirjaudu sisään",
        "Sign in": "Kirjaudu sisään",
        "Sign Up": "Rekisteröidy",
        "Create Account": "Luo tunnus",
        Email: "Sähköposti",
        Password: "Salasana",
        "Confirm Password": "Vahvista Salasana",
        "Phone Number": "Puhelinnumero",
        "Email or Phone Number": "Sähköposti tai puhelinnumero",
    },
    en: {
        "Sign In": "Sign In",
        "Sign in": "Sign in",
        "Sign Up": "Sign Up",
        "Create Account": "Create Account",
        Email: "Email",
        Password: "Password",
        "Confirm Password": "Confirm Password",
        "Phone Number": "Phone Number",
        "Email or Phone Number": "Email or Phone Number",
    },
});
function PrivateAppRoutes() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { contextOrder, setContextOrder } = useContext(OrderDataContext);
    const { i18n } = useTranslation();

    const origin = window.location.origin.toString();
    const logoUrl = useMemo(() => {
        if (origin.includes("test2travel")) {
            return T2TLogo;
        }
        if (origin.includes("health4travel")) {
            return H2TLogo;
        }
        return H2TLogo;
    }, [origin]);

    //Customize headers and footers if needed
    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image alt="Logo" src={logoUrl} height="50px" />
                </View>
            );
        },

        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Text color={`${tokens.colors.neutral["80"]}`}>&copy; All Rights Reserved</Text>
                </View>
            );
        },

        SignIn: {
            Header() {
                const { tokens } = useTheme();
                const { t } = useTranslation();

                return (
                    <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
                        {t("private-app-routes.sign-in")}
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();
                const { t } = useTranslation();

                return (
                    <View textAlign="center">
                        <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
                            {t("private-app-routes.reset-pw")}
                        </Button>
                    </View>
                );
            },
        },
    };

    Hub.listen("auth", (data) => {
        switch (data.payload.event) {
            case "signIn":
                setIsLoading(true);
                getLastOrder(data.payload.data.username)
                    .then((data) => {
                        if (data) {
                            setContextOrder(data as IOrderData);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        logger.error(error);
                        //console.log(error);
                        alert(error);
                    });
                navigate("/app/home");
                break;
            case "signUp":
                //TODO don't need here?
                logger.debug("user signed up");
                //console.log('user signed up');
                break;
            case "signOut":
                logger.debug("user signed out");
                //console.log('user signed out');
                navigate("/app/login");
                break;
            case "signIn_failure":
                logger.debug("user sign in failed");
                //console.log('user sign in failed');
                //TODO some kind of Alert?
                break;
            case "configured":
                logger.debug("the Auth module is configured");
            //console.log("the Auth module is configured");
        }
    });

    const services = {
        async handleForgotPassword(userName) {
            let trimmedUserName = userName.trim() as string;
            trimmedUserName = trimmedUserName.toLowerCase();
            return Auth.forgotPassword(trimmedUserName);
        },
        async handleForgotPasswordSubmit(formData) {
            const { username, code, password } = formData;
            let trimmedUserName = username.trim() as string;
            trimmedUserName = trimmedUserName.toLowerCase();
            return Auth.forgotPasswordSubmit(trimmedUserName, code, password);
        },
        async handleSignIn(formData) {
            const { username, password } = formData;
            let trimmedUserName = username.trim() as string;
            trimmedUserName = trimmedUserName.toLowerCase();
            return Auth.signIn({
                username: trimmedUserName,
                password,
            });
        },
        async handleConfirmSignIn(formData) {
            const { user, code, mfaType } = formData;

            return Auth.confirmSignIn(user, code, mfaType);
        },
    };

    return (
        <View>
            <View className="private" height="calc(100vh - 180px)">
                <Authenticator
                    className="signup"
                    services={services}
                    components={components}
                    initialState="signIn"
                    signUpAttributes={[]}>
                    {({ signOut, user }) => (
                        <div>
                            {isLoading && <SpinLoader />}
                            {!isLoading && (
                                <OrderDataContext.Provider value={{ contextOrder, setContextOrder }}>
                                    <Routes>
                                        {Object.entries(privateRoutes).map(([key, route]) => {
                                            const Component = COMPONENTS[route.component];
                                            return (
                                                <Route
                                                    key={`${route.paths[i18n.language] ?? route.paths.en}`}
                                                    path={`${route.paths[i18n.language] ?? route.paths.en}`}
                                                    element={<Component signout={signOut} user={user} />}
                                                />
                                            );
                                        })}
                                    </Routes>
                                </OrderDataContext.Provider>
                            )}
                        </div>
                    )}
                </Authenticator>
            </View>
        </View>
    );
}

const DEFAULT_ROUTES = {
    home: {
        path: "/home",
        component: "PrivateHomePage",
    },
};

PrivateAppRoutes.defaultProps = {
    privateRoutes: DEFAULT_ROUTES,
};

export default PrivateAppRoutes;
