import { createGlobalStyle } from "styled-components";

export const responsiveStyles = {
    buttons: {
        width: {
            base: "300px",
            small: "300px",
            medium: "320px",
            large: "320px",
        },
    },
    image: {
        icon: {
            width: {
                base: "100px",
                small: "100px",
                medium: "130px",
                large: "200px",
            },
            height: {
                base: "auto",
            },
        },
    },
    card: {
        time: {
            width: {
                base: "100px",
                small: "110px",
                medium: "110px",
                large: "110px",
            },
        },
    },
    icon: {
        color: `${({ theme }: any) => theme.colors.icon.color}`,
    },
    text: {
        fontSize: {
            base: "16px",
            small: "18px",
            medium: "20px",
        },
        testimonialsText: {
            fontSize: {
                base: "16px",
                small: "18px",
            },
        },
    },
};

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }

  .hidden {
    visibility: hidden;
  }

  .center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .map-container {
    height: 500px;
    width: 100%;

  }

  .icon, .icon svg {
    color: ${({ theme }: any) => theme.colors.icon.color};
    background: ${({ theme }: any) => theme.colors.icon.background}
  }

  .icon-green, .icon-green svg {
    color: #28C840;
    background: white;
  }

  .icon-background {
    color: ${({ theme }: any) => theme.colors.icon.background};
  }

  .icon-dark, .icon-dark svg {
    color: ${({ theme }: any) => theme.colors.icon.color};
    background: ${({ theme }: any) => theme.avcDarkBlue}
  }

  .icon-dark-background, .icon-dark-background svg {
    color: ${({ theme }: any) => theme.avcDarkBlue};
    background: ${({ theme }: any) => theme.colors.icon.color}
  }

  .security-info a {
    text-decoration: underline;
  }

  body {
    background: ${({ theme }: any) => theme.colors.body};
    color: ${({ theme }: any) => theme.colors.text};
    font-family: ${({ theme }: any) => theme.font};
    transition: all 0.15s linear;
    margin-left: 0%;
    margin-right: 0%;
  }

  [data-amplify-authenticator] [data-amplify-router] {
    box-shadow: none;
  }

  .amplify-text {
    color: ${({ theme }: any) => theme.avcDarkBlue}
  }

  .amplify-radio__button {
    border-color: black;
    background-color: white;
  }

  .amplify-radio__input:checked + .amplify-radio__button {
    color: ${({ theme }: any) => theme.avcDarkBlue}
    border-color: black;
    background-color: ${({ theme }: any) => theme.avcLightBlue}
  }

  /* Checkbox style */
  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 25px;
    height: 25px;
    border: 0.15em solid currentColor;
    border-radius: 4px;
    transform: translateY(-0.075em);
  }

  .amplify-text {
    font-size: 14px;
    font-family: ${({ theme }: any) => theme.font};
  }

  .private [data-amplify-authenticator] [data-amplify-container] .amplify-tabs {
    display: none;
  }

  [data-amplify-authenticator] [data-amplify-container] .amplify-button[data-variation='primary'] {
    background-color: ${({ theme }: any) => theme.colors.primaryButton.backgroundColor};
    color: ${({ theme }: any) => theme.colors.primaryButton.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    height: 50px;
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  input[type="checkbox"] {
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 15px;
    height: 15px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }: any) => theme.avcLightBlue};
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  /* Checkbox style end */

  .border-left {
    border-left: 1px dashed lightgray;
  }

  .display-block {
    display: block;
  }

  /* Scroll bar style */

  .scrollBar {
    max-width: 360px;
  }

  .menu-item {
    padding: 0 40px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
  }

  .menu-item-wrapper.active {
    border: 1px blue solid;
  }

  .menu-item.active {
    border: 1px green solid;
  }

  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }

  /*Scroll bar style end */

  .body-l {
    font-family: ${({ theme }: any) => theme.font};
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 1px;
  }

  .body-sm-bold {
    font-family: ${({ theme }: any) => theme.font};
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
  }

  .body-sm {
    font-family: ${({ theme }: any) => theme.font};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
  }

  .btn {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
  }

  .btn-wrap {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  .testimonials {
    background-color: ${({ theme }: any) => theme.colors.testimonials.backgroundColor};
    padding: 24px;
  }

  .testimonials_whitebackground {
    background-color: ${({ theme }: any) => theme.colors.testimonials_whitebackground.backgroundColor};
    padding: 24px;
  }

  .latest-travel-header {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: ${({ theme }: any) => theme.avcDarkBlue};
    font-family: Lato;
    letter-spacing: -0.01em;
  }

  .light-blue {
    color: ${({ theme }: any) => theme.avcLightBlue};
  }

  .vaccine-box button:hover {
    background-color: transparent;
  }

  .primary-button {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    background-color: ${({ theme }: any) => theme.colors.primaryButton.backgroundColor};
    color: ${({ theme }: any) => theme.colors.primaryButton.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    height: 50px;
    max-width: 300px;
    display: flex;
    justify-content: center;
    font-family: Lato;
    font-style: normal;
    border-width: 0px;

    letter-spacing: -0.02em;
    
  }

  .primary-button-blue {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    background-color: ${({ theme }: any) => theme.colors.primaryButtonBlue.backgroundColor};
    color: ${({ theme }: any) => theme.colors.primaryButtonBlue.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    height: 50px;
    display: flex;
    width: 200px;
    max-width: 300px;
    justify-content: center;
    border-width: 0px;
  }

  .primary-button-sm {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    background-color: ${({ theme }: any) => theme.colors.primaryButton.backgroundColor};
    color: ${({ theme }: any) => theme.colors.primaryButton.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
  }

  .info-text-box {
    background: ${({ theme }: any) => theme.colors.testimonials.backgroundColor};;
    mix-blend-mode: normal;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }

  .primary-button:disabled {
    background-color: ${({ theme }: any) => theme.colors.primaryButton.disabledBackgroundColor};
    color: ${({ theme }: any) => theme.colors.primaryButton.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    height: 50px;
  }

  .timeslot-infobox {
    background: #EFF8FC;
    border-radius: 2px;
    padding: 8px;
  }

  .plus-minus-btn button:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  .service-steps {
    color: ${({ theme }: any) => theme.avcDarkBlue};
  }

  .step-circle {
    position: absolute !important;
    left: -14px;
  }

  .vaccine-box {
    display: flex;
    grid-template-columns: 45px auto 110px;
    gap: 0px;
  }

  .vaccine-box-border {
    border: 1px solid ${({ theme }: any) => theme.avcDarkBlue};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .vaccine-box-border:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }

  .vaccine-box-texts {
    color: ${({ theme }: any) => theme.avcDarkBlue};
    letter-spacing: -0.01em !important;
  }

  /* Radio button */
  .radio-toolbar {
    display: grid;
    grid-template-columns: auto auto;

  }

  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;

  }

  .radio-toolbar label {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }: any) => theme.colors.radio.backgroundColor};
    min-height: 20px;
    font-size: 18px;
    padding: 10px;
    text-align: center;
    border-radius: 30px;
    width: 100%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .radio-toolbar label:hover {
    background-color: ${({ theme }: any) => theme.colors.radio.backgroundHover};
  }

  .radio-toolbar input[type="radio"]:focus + label {
  }

  .radio-toolbar input[type="radio"]:checked + label {
    background-color: ${({ theme }: any) => theme.colors.radio.backgroundHover};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    color: white;
  }

  /* Radio button end */

  .horizontal-center {
    margin: 0;
    position: relative;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  
  .vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  /* Custom Checkboxes */

  #ck-button {
    width: 100%;
  }

  #ck-button label {
    float: left;
    width: 100%;
    padding: 12px;
  }

  #ck-button input {
    display: none;
  }

  #ck-button label span {
    text-align: center;
    padding: 10px 0px;
    display: block;
    border-radius: 4px;
    background-color: ${({ theme }: any) => theme.colors.radio.backgroundColor};
    font-size: 16px;
    border-radius: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  #ck-button label input {
    position: absolute;
    top: -20px;
  }

  #ck-button input:hover + span {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  }

  #ck-button input:checked + span {
    background-color: ${({ theme }: any) => theme.colors.radio.backgroundHover};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    color: white;
  }

  #ck-button input:checked:hover + span {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  }

  /* Checkboxes End */

  .display-flex {
    display: flex;
  }

  .display-flex-to-grid {
    display: flex;
  }

  .contact {
    background-color: ${({ theme }: any) => theme.avcDarkBlue};
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 32px 24px;
  }

  .h1-header {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    letter-spacing: -0.01em;
  }

  .h1-xs-header {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.01em !important;
  }

  .h3-header {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  .h2-header {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
  }

  .h2-xs-header {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.01em;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
    color: ${({ theme }: any) => theme.avcDarkBlue};
  }

  p {
    font-size: 14px;
    letter-spacing: 0.01em !important;
    font-family: ${({ theme }: any) => theme.font};
  }

  h3 {
    font-family: 'Trebuchet MS';
    font-size: 18px;
    color: ${({ theme }: any) => theme.avcDarkBlue};
  }

  h4 {
    font-family: 'Trebuchet MS';
    font-size: 16px;
    margin: 0;
  }

  .text-container-item p {
    font-size: 14px;
  }

  button {
    font-size: 12px;
    font-weight: 600;
  }

  .powered-by {
    height: 120px;
    justify-content: center;
    align-items: center;
  }

  .center {
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  .swiper-button-next, .swiper-button-prev {
    top: 84% !important;
    text-align: center;
    width: 50px !important;
    height 50px !important;
    background-color: ${({ theme }: any) => theme.colors.primaryButton.backgroundColor};
    border-radius: 50px;
    color: ${({ theme }: any) => theme.colors.button.text} !important;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 25px !important;
    width: 50px !important;
    height 50px !important;
    top: 25% !important;
    position: absolute;
    border-radius: 50px;
  }

  .gray-border {
    border-bottom: 1px solid lightgray
  }

  .grid-temp-details {
    grid-template-columns: 50% 50%;
  }

  .ck-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0px;
  }

  .qr-card {
    height: 584px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .secondary-timecard {
    background: ${({ theme }: any) => theme.colors.timecardSecondary.background};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }

  .primary-timecard {
    background: ${({ theme }: any) => theme.colors.timecardPrimary.background};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }

  .test-provider-card {
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 24px 16px;
  }

  .travel-rule-text {
    background: ${({ theme }: any) => theme.colors.testimonials.backgroundColor};
    border-radius: 6px;
    min-height: 119px;
  }

  .pointer {
    cursor: pointer;
  }

  .secondary-button {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    background-color: ${({ theme }: any) => theme.colors.secondaryButton.backgroundColor};
    color: ${({ theme }: any) => theme.colors.secondaryButton.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    width: 320px;
    height: 50px;
  }

  .third-button {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    background-color: ${({ theme }: any) => theme.avcLightBlue};
    color: ${({ theme }: any) => theme.colors.nav.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    width: 320px;
    height: 50px;
  }

  .login-button {
    background-color: ${({ theme }: any) => theme.colors.primaryButton.backgroundColor};
    color: ${({ theme }: any) => theme.colors.primaryButton.color};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    width: 320px;
    height: 50px;
    margin: 5px;
  }

  .App-header {
    background-color: ${({ theme }: any) => theme.colors.appHeader.backgroundColor};
    color: ${({ theme }: any) => theme.colors.appHeader.color};
    font-family: ${({ theme }: any) => theme.font};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .App-body {
    background-color: ${({ theme }: any) => theme.colors.appBody.backgroundColor};
    color: ${({ theme }: any) => theme.colors.appBody.color};
    font-family: ${({ theme }: any) => theme.font};
  }

  .App-footer {
    background-color: ${({ theme }: any) => theme.colors.appBody.backgroundColor};
    color: ${({ theme }: any) => theme.colors.appBody.color};
    font-family: ${({ theme }: any) => theme.font};
  }

  .App-link {
    color: #61dafb;
  }

  a {
    color: ${({ theme }: any) => theme.colors.link.text};
    cursor: pointer;
  }

  button {
    border: 0;
    display: inline-block;
    padding: 0;
    font-size: 12px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #1064EA;
    color: #FFFFFF;
    font-family: ${({ theme }: any) => theme.font};
  }

  button.btn {
    background-color: ${({ theme }: any) => theme.colors.button.background};
    color: ${({ theme }: any) => theme.colors.button.text};
  }

  .nav-icon {
    color: ${({ theme }: any) => theme.colors.nav.backgroundColor}
  }

  .nav-icon-close {
    color: ${({ theme }: any) => theme.colors.nav.color}
  }

  .nav-bar {
    width: 100%;
    position: fixed;
    top: 0;
  }

  .nav-bar ul {
    list-style-type: none;
    margin: 0;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }

  .nav-bar ul li {
    margin: 10px;
  }

  .nav-bar ul li a {
    text-decoration: none;
    color: white;
  }

  .nav-bar ul li a.active {
    color: cornflowerblue;
  }

  /* BOTTOM MENU */
  .nav {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-right: 25%;
    padding-left: 25%;
    width: 100%;
    min-width: 300px;
    height: 85px;
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
  }

  .nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: ${({ theme }: any) => theme.font};
    font-size: 12px;
    letter-spacing: 0.07em;
    letter-spacing: 0.07em;
    line-height: 14px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
  }

  .nav__link span {
    font-size: 12px;
    padding: 4px;
  }

  .nav__link:hover {
    color: black;
  }

  .nav__link--inactive svg,
  .nav__link--inactive svg {
    color: ${({ theme }: any) => theme.avcDarkBlue};
    background: ${({ theme }: any) => theme.colors.icon.inactiveBackground};
    height: 36px;
    width: 36px;
    padding: 8px;
    border-radius: 36px;
  }

  .nav__link--active,
  .nav__link--active {
    color: ${({ theme }: any) => theme.avcDarkBlue};
    font-weight: 600;
  }

  .nav__link--active svg,
  .nav__link--active svg {
    color: ${({ theme }: any) => theme.colors.icon.color};
    background: ${({ theme }: any) => theme.avcLightBlue};
    height: 36px;
    width: 36px;
    padding: 8px;
    border-radius: 36px;
  }

  .nav__link--active nav__text,
  .nav__link--active nav__text {
    color: ${({ theme }: any) => theme.avcDarkBlue};
    font-weight: 600;
  }

  .nav__link--inactive,
  .nav__link--inactive {
    color: ${({ theme }: any) => theme.avcDarkBlue};
    font-weight: 600;
  }

  .nav__icon {
    font-size: 18px;
    height: 28px;
    width: 28px;
  }


  /* Bottom Menu end */

  .travel_icon--active-dark svg,
  .travel_icon--active-dark svg {
    color: ${({ theme }: any) => theme.colors.icon.color};
    background: ${({ theme }: any) => theme.avcDarkBlue};
    height: 34px;
    width: 34px;
    padding: 8px;
    border-radius: 25px;
  }

  .travel_icon--active svg,
  .travel_icon--active svg {
    color: ${({ theme }: any) => theme.colors.icon.color};
    background: ${({ theme }: any) => theme.colors.icon.background};
    height: 34px;
    width: 34px;
    padding: 8px;
    border-radius: 25px;
  }

  .travel_icon--inactive svg,
  .travel_icon--inactive svg {
    color: ${({ theme }: any) => theme.colors.icon.inactiveColor};
    background: ${({ theme }: any) => theme.colors.icon.inactiveBackground};
    height: 34px;
    width: 34px;
    padding: 8px;
    border-radius: 25px;
  }

  .travel_icon {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 12px;
    letter-spacing: 0.07em;
    letter-spacing: 0.07em;
    line-height: 14px;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
  }

  .travel_icon_results {
    color: ${({ theme }: any) => theme.colors.icon.background};
  }

  .travel-box-title {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: ${({ theme }: any) => theme.colors.travelBox.color};
    letter-spacing: 0.02em;
  }

  .travel-box-text {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0.02em;
  }

  .cards-container {
    display: flex;
    margin: 20px 0;
  }

  .hide {
    display: none;
  }

  .text-padding {
    padding: 20px;
  }

  .padding-sm {
    padding-left: 5px;
    padding-right: 5px;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    background-color: ${({ theme }: any) => theme.colors.nav.backgroundColor};
    overflow-x: hidden;
    transition: 0.5s;
  }

  .title-font {
    font-weight: 600 !important;
    width: 100% !important;
    line-height: 34px !important;
    font-size: 32px !important;
  }

  .title-font-sm {
    font-weight: bold !important;
    width: 100% !important;
    line-height: 28px !important;
    font-size: 24px !important;
  }

  /*
  TESTS
  */
  .text-container-item:before {
    content: counter(section);
    right: 100%;
    margin-right: -15px;
    margin-top: 24px;
    position: absolute;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: gray;
    text-align: center;
    color: white;
    font-size: 110%;

    background: #fff;
    border: 2px solid #666;
    color: ${({ theme }: any) => theme.colors.nav.backgroundColor};
  }

  .text-container-item {
    /* Increment "my-sec-counter" by 1 */
    counter-increment: section;
    content: "Section " counter(section) ". ";
    border-top: 2px dashed;
    border-color: gray !important;
    margin: 0;
    padding: 30px;
    position: relative;
  }

  .text-container-item {
    border-left: 2px solid;
    margin-left: 30px;
    padding-left: 30px;

    border-top: 2px;
    border-left: 2px dashed;
    border-bottom: 2px;
  }

  .text-container-item:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .text-container-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .text-container-item h2 {
    font-size: x-large;
  }

  .text-container {
    padding: 50px;
  }

  .text-container {
    padding: 0px;
  }

  /*
  TESTS
  */

  #mapid {
    height: 250px;
    width: 100%;
    margin: 0px;
  }

  #mapid-home {
    height: 300px;
    width: 100%;
    margin: 0px;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 10px !important;
  }

  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;

  }

  .overlay-content ul {
    padding-left: 0px;
  }

  .flex-img-text {
    display: flex;
  }

  .overlay-content a {
    font-weight: 700;
    text-decoration: none;
    color: ${({ theme }: any) => theme.colors.nav.color};
  }

  li {
    list-style: none;
  }

  .overlay-content li {
    color: ${({ theme }: any) => theme.colors.nav.color};
  }

  .show-li-deco li {
    list-style: disc;
    font-size: 14px;
  }

  .show-li-deco ul {
    margin: 0;
    padding-left: 15px;
  }

  .li-padding li {
    padding: 8px 0px;
  }

  .image-container {
    width: 100%;

  }

  .lang-icon {
    font-size: 34px;
    border-radius: 50%;
    width: auto;
  }


  @media (max-width: 550px) {
    body {
      margin-left: 0;
      margin-right: 0;
    }

  }

  @media (min-width: 550px) {
    body {
      margin-left: 0%;
      margin-right: 0%;
    }

    
  }

  @media (max-width: 500px) {
    .cards-container {
      display: block;
      margin: 20px 0;
    }

    .nav {
      position: fixed;
      bottom: 0;
      left: 0;
      padding-right: 0px;
      padding-left: 0px;
      width: 100%;
      height: 85px;
      background-color: #ffffff;
      display: flex;
      overflow-x: auto;
    }

    .nav {
      padding-right: 0px;
      padding-left: 0px;
      width: 100%;
    }

    .scrollBar {
      max-width: 300px;
    }

    .overlay a {
      font-size: 20px
    }

    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }

    .contact {
      background-color: ${({ theme }: any) => theme.avcDarkBlue};
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .image-container {
      width: 100vw;

    }

  }

  @media (max-width: 350px) {
    .flex-img-text {
      display: flex;
    }

    .scrollBar {
      max-width: 270px;
    }

    .radio-toolbar {
      display: grid;
      grid-template-columns: auto;
      justify-content: center;
    }
  }

  @media (min-width: 768px) {
    .scrollBar {
      max-width: 400px;
    }

    body {
      margin-left: 0%;
      margin-right: 0%;
    }

  }

  @media (max-width: 1024px) {
    .desktopNavBar {
      display: none !Important;
    }
  }

  .desktopNav {
    background: #fff;
  }

  @media (min-width: 1024px) {
    .desktopNavBar {
      display: block !Important;
      box-shadow: none !Important;
    }

    .mobileNavBar {
      display: none !Important;
    }

    .loginButtonText {
      font-size: 12px !Important;
    }

    .primary-button-login {
      width: 90px !Important;
      height: 30px !Important
    }

    .image-container {
      height: 600px;
      object-fit: cover;
      object-position: 0 0;
    }

    .desktopNavLink {
      font-family: ${({ theme }: any) => theme.font};
      color: ${({ theme }: any) => theme.avcDarkBlue};
      display: flex;
      justify: space-around;
      text-transform: none;
      font-weight: bold;
      font-size: 14px;
      padding-right: 5px;
    }

    .activeNavLink {
      color: ${({ theme }: any) => theme.avcLightBlue} !Important;
    }

    .nav {
      position: fixed;
      bottom: 0;
      left: 0;
      padding-right: 35%;
      padding-left: 35%;
      width: 100%;
      min-width: 300px;
      height: 85px;
      background-color: #ffffff;
      display: flex;
      overflow-x: auto;
    }

    body {
      margin-left: 0;
      margin-right: 0;
    }

    .test-provider-card {
      margin-left: 15%;
      margin-right: 15%;
    }

    .h3-header {
      text-align: center;
    }

    #mapid-home {
      height: 500px;
      width: 70%;
      margin-left: 15%;
      margin-right: 15%;
    }

    .latest-travel-info {
      font-size: 18px;
    }

    .scrollBar {
      max-width: 550px;
    }
  }

  @media (min-width: 1440px) {
    body {
      max-width: 1728px;
      margin-left: auto;
      margin-right: auto;
    }

    .latest-travel-header {
      font-size: 48px;
    }

    .image-container {
      height: 600px;
      object-fit: cover;
      object-position: 0 0;
    }

    .travel-rules, .book-test {
      font-size: 24px;
      height: 70px;
    }

    .office_search_filter, {
      font-size: 18px;
      height: 50px;
    }

    .desktopNavLink {
      padding-right: 30px;
      font-size: 18px;
    }

    .lang-icon {
      margin-left: 30px;
    }

    .loginButtonText {
      font-size: 18px !Important;
    }

    .primary-button-login {
      width: 180px !Important;
      height: 40px !Important
    }

  }

  @media (min-width: 2560px) {
    .travel-rules, .book-test {
      font-size: 28px;
      height: 75px;
    }

    .loginButtonText {
      font-size: 22px !Important;
    }

    .lang-icon {
      font-size: 45px;
      margin-left: 50px;
    }

    .primary-button-login {
      width: 230px !Important;
      height: 45px !Important
    }

  }

  .card-container {
    width: 320px;
    margin: 5px;
    background-color: white;
    color: black;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .card-header, .card-footer {
    padding: 10px;
  }

  .card-title, .card-description {
    margin: 0;
  }

  .card-image-rd {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .card-description {
    font-size: 18px;
    text-align: left;
  }

  .featured-product {
    display: flex;
    margin-top: 20px;
    background-color: white;
    color: black;
    border-radius: 20px;
    height: 240px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .featured-img {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .featured-content {
    padding: 10px;
    text-align: left;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }


  .search-menu {
    /* Search-menu */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 0px;

    position: relative;
    //width: 1728px;
    width: 100%;
    //height: 311px;
    left: 0px;
    //top: 130px;


  }
  
   .dark-background {
    background: #183A59;
  }



  .frame-main-header2 {
    text-align: center;
    /* Frame Main Header2 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    //width: 1728px;
    width: 100%;
    //height: 173px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 1;
  }

  .frame-551 {
    /* Frame 551 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 0px 0px 0px;

    //width: 1696px;
    width: 100%;
    //height: 65px;


    /* Inside auto layout */

    flex: none;
    //order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .text-main-title {
    /* Travel stress-free book medical services easily */


    //width: 1696px;
    width: 100%;
    //height: 65px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 65px;
    /* identical to box height */

    //display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    //order: 0;
    flex-grow: 1;
  }

  .text-main-title-dark {
    /* Travel stress-free book medical services easily */


    width: 100%;
    //height: 38px;

    /* Header 1 - noto sans */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    
    gap: 10px;

    color: #183A59;


    /* Inside auto layout */

    flex: none;
    //order: 0;
    flex-grow: 1;
  }
  .frame-552 {
    /* Frame 552 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    //width: 1696px;
    width: 100%;
    //height: 26px;


    /* Inside auto layout */

    flex: none;
    //order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .text-main-title2 {
    text-align: center;

    /* Book an appointment with one of our trusted medical service providers near you. */


    //width: 1696px;
    width: 100%;
    //height: 26px;

    /* Header 3 - lato */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    //display: flex;
    align-items: center;
    text-align: center;

    /* AVC - blue */

    color: #3398F4;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .frame-553 {
    /* Frame 553 */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    //width: 1696px;
    width: 100%;
    //height: 34px;


    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }

  .text-main-title3 {
    /* Find a medical service */


    //width: 1696px;
    width: 100%;
    //height: 34px;

    /* Header 3 - lato */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    //display: flex;
    align-items: center;
    text-align: center;

    color: #CAF1FF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .frame-search {
    /* Frame search */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    //gap: 24px;

    //width: 1728px;
    //width: 100%;
    //height: 90px;


    /* Inside auto layout */

    flex: none;
    //order: 1;
    flex-grow: 0;
  }
  
  .frame-search-item {
    /* Form-input */


    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 10px;
    gap: 10px;

    //width: 294px;
    //height: 42px;

    /* AVC-Light-Blue */

    //border: 1px solid #12AAF5;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.15));
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    //order: 1;
    flex-grow: 0;
  }

  .homepage-session {
    /* Frame 764 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px 24px 0px;

    position: relative;
    width: 100%;
    //height: 530px;
    left: 0px;
    //top: 474px;

  }

  .homepage-session-title {
    /* Frame 576 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 100%;
    //height: 78px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .homepage-session-title-text {

    /* Medical service locations */


    //width: 1708px;
    //height: 58px;

    /* Header 2 - lato */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    //display: flex;
    align-items: center;
    text-align: center;

    /* AVC-Blue */

    color: #183A59;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
  }

  .frame-557-travelrestrictions {
    /* Frame 557 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 743px;
    height: 156px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .travelrestrictions-text {
    /* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sollicitudin neque cursus ipsum ornare aliquam. Vestibulum et consequat est, eu pharetra elit. */


    //width: 743px;
    //height: 36px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* or 129% */

    text-align: center;
    letter-spacing: 0.01em;

    padding: 20px;

    /* AVC-dark blue */

    color: #183A59;


    /* Inside auto layout */

    flex: none;
    //order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .homepage-frame-session {
    /* Frame medical */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0px 24px 0px;
    gap: 120px;

    position: relative;
    width: 100%;
    //height: 398px;
    left: 0px;
    //top: 1240px;


  }

  .homepage-frame-session-background {
    background: #EDF5FA;
  }

  .homepage-frame-session-pic {
    /* Travel */


    //width: 530px;
    //height: 350px;


    /* Inside auto layout */

    flex: none;
    //order: 0;
    flex-grow: 0;
  }

  .homepage-frame-session-text-session {
    /* Frame 559 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 50px 32px 50px;
    gap: 16px;

    position: relative;
    top: 50%;
    transform: translateY(-50%);

    //width: 416px;
    //height: 265px;


    /* Inside auto layout */

    flex: none;
    //order: 1;
    flex-grow: 0;
  }


  .homepage-frame-session-text-session-noalign {
    /* Frame 559 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 50px 32px 50px;
    gap: 16px;

    position: relative;
    //top: 50%;
    //transform: translateY(-50%);

    //width: 416px;
    //height: 265px;


    /* Inside auto layout */

    flex: none;
    //order: 1;
    flex-grow: 0;
  }

  .homepage-frame-session-title {
    /* Book medical services easily on the go */


    //width: 416px;
    //height: 78px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.02em;

    /* AVC-Blue */

    color: #183A59;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .homepage-frame-session-text {
    /* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sollicitudin neque cursus ipsum ornare aliquam. Vestibulum et consequat est, eu pharetra elit. Integer orci nisi, accumsan non accumsan sollicitudin, lobortis at risus. Mauris vel vestibulum felis. Phasellus eu lorem et turpis varius fermentum. */


    //width: 416px;
    //height: 105px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 129% */

    letter-spacing: 0.01em;

    /* AVC-dark blue */

    color: #183A59;

    padding: 20px 0px 20px 0px;

    /* Inside auto layout */

    flex: none;
    //order: 1;
    align-self: stretch;
    flex-grow: 1;
  }
  .homepage-frame-session-subheader {
    /* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sollicitudin neque cursus ipsum ornare aliquam. Vestibulum et consequat est, eu pharetra elit. Integer orci nisi, accumsan non accumsan sollicitudin, lobortis at risus. Mauris vel vestibulum felis. Phasellus eu lorem et turpis varius fermentum. */


    //width: 416px;
    //height: 105px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    /* or 129% */

    letter-spacing: 0.01em;

    /* AVC-dark blue */

    color: #183A59;

    padding: 20px 0px 20px 0px;

    /* Inside auto layout */

    flex: none;
    //order: 1;
    align-self: stretch;
    flex-grow: 1;
  }
  
  .homepage-frame-session-bullet-text{
    /* Frame 760 */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    
    /* Auto layout */
    display: flex;
    align-items: flex-end;
    padding: 0px;
    gap: 16px;



    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .testimonial-grid {
    /* Frame 565 */


    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 100%;
    //height: 250px;

    padding: 34px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .testimonial-grid-pic {
    /* Style=Outlined */


    width: 48px;
    height: 48px;


    /* Inside auto layout */

    flex: none;
    //order: 0;
    flex-grow: 0;
  }

  .testimonial-grid-text {
    /* “With Health4Travel.com I was able to book my travel vaccination easily and get out and travel”  - Pia Kokkonen */


    width: 100%;
    //height: 178px;

    font-family: 'Lato';
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;

    /* AVC-dark blue */

    color: #183A59;


    /* Inside auto layout */

    //flex: none;
    //order: 1;
    flex-grow: 0;
  }

  .frame-search-icon {
    /* content-icon-med */


    width: 34px;
    height: 34px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }


  @media (max-width: 1200px) {
    .text-main-title {
      /* Travel stress-free book medical services easily */


      width: 100%;
      //height: 38px;

      /* Header 1 - noto sans */

      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 38px;
      //display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.01em;

      color: white;


      /* Inside auto layout */

      flex: none;
      //order: 0;
      flex-grow: 1;
    }


    .text-main-title2 {
      /* Book an appointment with one of our trusted medical service providers near you. */


      //width: 778px;
      //height: 52px;

      /* Header 4 - lato */

      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      //display: flex;
      align-items: center;
      text-align: center;

      /* AVC - blue */

      color: #183A59;


      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
    }

    .frame-search {
      flex-direction: column;
    }

  }
  @media (max-width: 768px) {
    .text-main-title {
      font-size: 32px;
      line-height: 38px;
    }
    .homepage-frame-session-text-session {
      padding: 10px 10px 10px 10px;
    }
  }

  @media (max-width: 500px) {
    .frame-551 {

      padding: 32px 20px 0px 20px;

    }
    .primary-button {
      font-size: 16px;
    }
    .homepage-frame-session-text-session {
      padding: 0px;
    }
    .homepage-frame-session-text {
      padding: 0px;
    }
    
  }
  /*@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }*/

  /*@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }*/

`;
