import { ChangeEventHandler } from "react";
import { Grid, Text, View } from "@aws-amplify/ui-react";
import { FormControl, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ITravelAutocompleteInputProps {
    icon: JSX.Element;
    text: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

//TODO add options for menuitems
function TravelAutocompleteInput(props: ITravelAutocompleteInputProps) {
    const { t } = useTranslation();
    const icon = props.icon;

    const handleChange = (event) => {
        props.onChange(event);
    };

    const className = "travel_icon--active-dark";

    return (
        <Grid templateColumns={"1fr 9fr"} margin="18px 0px">
            <Text className="travel-box-title" padding={"12px 0px"}></Text>
            <Text className="travel-box-title" padding={"12px 0px"}>
                {t("home-page.city-postal-code")}
            </Text>
            <View className={"travel_icon " + className}>{icon}</View>
            <FormControl fullWidth>
                <TextField
                    id="outlined-basic"
                    label=""
                    placeholder={t("home-page.city-postal-code-placeholder")}
                    disabled={false}
                    variant="outlined"
                    onChange={handleChange}
                    sx={{ border: "1px solid #183A59;", borderRadius: "4px;", background: "#eee" }}
                />
            </FormControl>
            <Text className="travel-box-title" padding={"12px 0px"}></Text>
            <Text className="travel-box-title" padding={"12px 0px"} style={{ color: "#183A59" }}>
                {t("home-page.more-cities-soon")}
            </Text>
        </Grid>
    );
}

export default TravelAutocompleteInput;
