import React from "react";
import ReactCountryFlag from "react-country-flag";

export default function CountryFlag(props: any) {
    const countryCode = props.code ? props.code : "fi";

    return (
        <ReactCountryFlag
            className="lang-icon"
            countryCode={countryCode}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
        />
    );
}
