import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { getConfig } from "../configurations/getConfig";
import logger from "../logger";

//import json_fi from './locales/fi/translation.json';

export interface En {
    translation: string;
}

export interface Fi {
    translation: string;
}

export interface Cn {
    translation: string;
}

export interface Nl {
    translation: string;
}
export interface Jp {
    translation: string;
}

export interface ILocale {
    en: En;
    fi: Fi;
    cn: Cn;
    nl: Nl;
    jp: Jp;

    [key: string]: any;
}

// the translations
const resources: ILocale = {
    en: {
        translation: "",
    },
    fi: {
        translation: "",
    },
    cn: {
        translation: "",
    },
    nl: {
        translation: "",
    },
    jp: {
        translation: "",
    },
};

//Get locales from config-file by customer
const locales = () => {
    const config = getConfig();
    logger.debug(config);
    //console.log(config);
    config.data.locales.forEach((element: string) => {
        if (resources[element]) {
            resources[element].translation = require(`../locales/${element}.json`);
        }
    });
};

locales();

//Get locales from config-file by customer
const getCurrentLang = () => {
    const currentLang = localStorage.getItem("language");
    return currentLang ? currentLang : "en";
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getCurrentLang(),

        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
