import React, { useContext } from "react";
import { ConfigContext } from "../../context/Config";
import { Text, Image, View, Flex } from "@aws-amplify/ui-react";
import { responsiveStyles } from "../../theme/GlobalStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function TextImageContainer({
    title,
    description,
    img,
    revert,
    stacked,
}: {
    title: string;
    description: string;
    img: string;
    revert?: boolean;
    stacked?: boolean;
}) {
    const data = useContext(ConfigContext);
    const card = data?.components?.card;

    return (
        <View margin="40px 24px ">
            {stacked && (
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "none", lg: "block" },
                    }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Image
                                borderRadius="50%"
                                className="padding-sm"
                                width={responsiveStyles.image.icon.width}
                                height={responsiveStyles.image.icon.height}
                                alt="Certificate"
                                src={img}></Image>
                        </Grid>
                        <Grid item xs={12}>
                            <Flex className="display-block">
                                <Text
                                    fontStyle="italic"
                                    fontSize={responsiveStyles.text.testimonialsText.fontSize}
                                    fontWeight="500"
                                    lineHeight="19px"
                                    letterSpacing="0.01em"
                                    className="padding-sm"
                                    maxWidth={"90%"}>
                                    {description}
                                </Text>
                                <h3 className="light-blue">{title}</h3>
                            </Flex>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!revert && (
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "block", lg: "none" },
                    }}>
                    <Flex className="flex-img-text">
                        <Image
                            borderRadius="50%"
                            width={responsiveStyles.image.icon.width}
                            height={responsiveStyles.image.icon.width}
                            alt="Certificate"
                            src={img}></Image>
                        <Flex className="display-block">
                            <Text
                                fontStyle="italic"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="19px"
                                letterSpacing="0.01em"
                                className="padding-sm">
                                {description}
                            </Text>
                            <h3 className="light-blue">{title}</h3>
                        </Flex>
                    </Flex>
                </Box>
            )}
            {revert && (
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "block", lg: "none" },
                    }}>
                    <Flex className="flex-img-text">
                        <Flex className="display-block">
                            <Text
                                fontStyle="italic"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="19px"
                                letterSpacing="0.01em"
                                className="padding-sm">
                                {description}
                            </Text>
                            <h3 className="light-blue">{title}</h3>
                        </Flex>
                        <Image
                            borderRadius="50%"
                            width={responsiveStyles.image.icon.width}
                            height={responsiveStyles.image.icon.width}
                            alt="Certificate"
                            src={img}></Image>
                    </Flex>
                </Box>
            )}
        </View>
    );
}

export default TextImageContainer;
