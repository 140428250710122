import React, { useContext, useMemo } from "react";
import T2TLogo from "./../../assets/img/test2travel-logo.svg";
import H2TLogo from "./../../assets/img/Health4Travel-logo.png";
import { NavContext } from "../../context/Config";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, IconClose, IconMenu, Image, Link } from "@aws-amplify/ui-react";
import LanguageSelector from "./LanguageSelector";

export default function NavBar(props: any) {
    const { overrides: overridesProp, showicon, ...rest } = props;
    const overrides = { ...overridesProp };

    const { navState, setNavState } = useContext(NavContext);

    const origin = window.location.origin.toString();

    const logoUrl = useMemo(() => {
        if (origin.includes("test2travel")) {
            return T2TLogo;
        }
        if (origin.includes("health4travel")) {
            return H2TLogo;
        }
        return H2TLogo;
    }, [origin]);

    const doShowNavContent = () => {
        setNavState({ showNav: !navState.showNav });
    };

    return (
        <Flex
            direction="row"
            alignItems="center"
            position="relative"
            padding="24px 32px 24px 32px"
            display="flex"
            {...rest}
            {...getOverrideProps(overrides, "Flex")}>
            <Flex
                gap="2px"
                direction="row"
                height="45px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="block"
                {...getOverrideProps(overrides, "Flex.Flex[0]")}>
                {showicon && (
                    <Link href="/">
                        <Image
                            alt="Logo"
                            width="164.95px"
                            height="54px"
                            src={logoUrl}
                            color="rgba(20.35486936569214,154.60028260946274,251.81250303983688,1)"
                            shrink="0"
                            position="relative"
                            ariaLabel=""
                            {...getOverrideProps(overrides, "Flex.Flex[0].Icon[0]")}></Image>
                    </Link>
                )}
                {!showicon && <Flex width="100px"></Flex>}
            </Flex>
            <Flex
                gap={2}
                direction="row"
                width="calc(100vw - 200px)"
                justifyContent={"right"}
                {...getOverrideProps(overrides, "Flex.Flex[1]")}>
                {showicon && <LanguageSelector></LanguageSelector>}
            </Flex>
            <Flex
                gap="0px"
                direction=""
                justifyContent="center"
                alignItems="center"
                width="50px"
                height="50px"
                position="relative"
                padding="0px 0px 0px 0px"
                className="navBar-icon"
                {...getOverrideProps(overrides, "Flex.Flex[2]")}>
                {showicon && (
                    <IconMenu
                        className="nav-icon"
                        fontSize="50px"
                        onClick={doShowNavContent}
                        alignItems="right"
                        color="#183A59"></IconMenu>
                )}

                {!showicon && (
                    <IconClose className="nav-icon-close" fontSize="50px" onClick={doShowNavContent}></IconClose>
                )}
            </Flex>
        </Flex>
    );
}
