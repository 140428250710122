import React, { useEffect, useState } from "react";
import TextImageContainer from "./../components/TextImageContainer";
import OfficeContent from "./../components/Content/OfficeContent";
import TestimonialsComponent from "../components/Images/TestimonialsComponent";
import calendarImg from "./../assets/img/Book.png";
import phoneImg from "./../assets/img/E-certificate.png";
import planeImg from "./../assets/img/vax.png";
import userOneImg from "./../assets/img/user-1.svg";
import userTwoImg from "./../assets/img/user-2.svg";
import userThreeImg from "./../assets/img/user-3.svg";
import ImageContainer from "../components/Images/ImageContainer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import poweredByImg from "./../assets/img/powered_by.svg";
import finImg from "./../assets/img/made_in_finland.svg";
import compassGif from "./../assets/img/compass.svg";
import { useNavigate } from "react-router-dom";
import AccordionSimpleComponent from "./../components/Inputs/AccordionSimpleComponent";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import L from "leaflet";
import imgSrc from "./../assets/img/jeshoots.png";
import { getAllOffices } from "../services/Offices";
import { Company } from "../types/Interfaces";
import ReactiveNavBar from "../components/Navigation/ReactiveNavBar";
import _ from "lodash";
import logger from "../logger";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import company1 from "../assets/img/company/company-Aava-Logo.png";
import company2 from "../assets/img/company/company-9lives-logo.png";
import company3 from "../assets/img/company/company-Medipulssi-logo.png";

const locationMarker = L.icon({
    iconUrl: require("./../assets/img/location-marker.png"),
    iconSize: [30, 30],
});

function HomePage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [offices, setOffices] = useState([] as Company[]);
    const titleTest = t("home-page.test-easily");
    const descriptionTest = t("home-page.choose-and-book");
    const titleGoTo = t("home-page.go-to");
    const descriptionGoTo = t("home-page.app-guides-to");
    const titleGetCert = t("home-page.get-certificate");
    const descriptionGetCert = t("home-page.covid-results-displayed");
    const handleNavigation = (path) => {
        navigate(path);
    };

    const user1 = "- " + t("home-page.testimonials.1");
    const user1Comment = t("home-page.testimonials.1-comment");
    const user2 = "- " + t("home-page.testimonials.2");
    const user2Comment = t("home-page.testimonials.2-comment");
    const user3 = "- " + t("home-page.testimonials.3");
    const user3Comment = t("home-page.testimonials.3-comment");

    const handleCheckTravelRules = () => {
        try {
            sessionStorage.setItem("vaccine", "");
            sessionStorage.setItem("vaccinePrice", "0");
            sessionStorage.setItem("vaccineType", "");
            navigate(t("public-links.travel-rules"));
        } catch (error) {
            alert("Failed to set items to storage: " + error);
        }
    };
    const handleTickVaccineNavigation = () => {
        // this is not in use for now
        try {
            sessionStorage.setItem("vaccine", "");
            sessionStorage.setItem("vaccinePrice", "0");
            sessionStorage.setItem("vaccineType", "");
            navigate(t("public-links.tick-vaccine-page"));
        } catch (error) {
            alert("Failed to set items to storage: " + error);
        }
    };

    const handleVaccineBaseNavigation = () => {
        try {
            sessionStorage.setItem("vaccine", "");
            sessionStorage.setItem("vaccinePrice", "0");
            sessionStorage.setItem("vaccineType", "");
            navigate(t("public-links.vaccine-base-page") + "/influenza"); // define the default page and filter setup
        } catch (error) {
            alert("Failed to set items to storage: " + error);
        }
    };

    function deleteAllCookies() {
        const cookies = document.cookie.split(";");
        localStorage.removeItem("contextOrder");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    useEffect(() => {
        //Clear cookies and local and session storages in home page
        //TODO: better solution later?
        deleteAllCookies();
        //localStorage.clear();
        sessionStorage.clear();
        getAllOffices()
            .then((resp) => {
                setOffices(resp as Company[]);
            })
            .catch((error) => {
                logger.error(error);
                //console.error(error);
            });
        //handle navigation to finish landing page by default
        const origin = window.location.origin.toString();
        if (origin.includes("rokotuskeskus.fi")) {
            navigate("/fi");
        }
    }, []);

    const faqComponents = [] as JSX.Element[];

    //TODO ADD FAQ TEXTS
    for (let index = 1; index < 21; index++) {
        faqComponents.push(
            <AccordionSimpleComponent
                title={t("faq." + index + "-title")}
                text={t("faq." + index + "-text")}></AccordionSimpleComponent>
        );
    }
    const officesExist = offices && offices.length > 0 ? true : false;

    function mappins(companies) {
        const mapmarkers = [] as JSX.Element[];

        _.forEach(companies, (company) => {
            company.offices.forEach((office) => {
                const mapcoordinateobj = office.coordinates.split(", ", 2);
                if (mapcoordinateobj.length === 2) {
                    mapmarkers.push(
                        <Marker position={mapcoordinateobj} icon={locationMarker}>
                            <Popup>{office.officeName}</Popup>
                        </Marker>
                    );
                }
            });
        });
        return mapmarkers;
    }

    return (
        <View>
            <Helmet>
                <meta charSet="utf-8" />
                {/*<title>My Title</title>*/}
                <meta name="title" content={t("meta-title")} />
                <meta name="description" content={t("meta-description")} />
            </Helmet>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <ReactiveNavBar />
                    </Grid>
                    <Grid item xs={12}>
                        <View lineHeight="0">
                            <ImageContainer img={imgSrc}></ImageContainer>
                        </View>
                    </Grid>
                    <Grid item xs={12}>
                        <View margin="24px 24px">
                            <Text className="latest-travel-header">
                                {t("home-page.plan-your-travels")}
                                <Box
                                    sx={{
                                        display: { xs: "none", sm: "block" },
                                    }}
                                    lineHeight="30%">
                                    <br />
                                </Box>
                                {t("home-page.book-a-test-easily")}
                            </Text>
                            <Flex height="24px"></Flex>
                            <Text className="latest-travel-info">{t("home-page.plan-your-travel-info")}</Text>
                            <Flex height="24px"></Flex>
                        </View>
                    </Grid>
                    <Grid container spacing={1} justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={5} lg={3} display={"flex"} justifyContent={"flex-end"}>
                            <Button
                                width="100%"
                                onClick={handleCheckTravelRules}
                                className="primary-button travel-rules">
                                {t("home-page.travel-rules")}
                                <ArrowForwardIosIcon></ArrowForwardIosIcon>
                            </Button>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                            <a href="#test-providers">
                                <Button width="100%" className="primary-button book-test">
                                    {t("home-page.book-test")}
                                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                </Button>
                            </a>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                            <Button
                                width="100%"
                                className="primary-button book-test"
                                onClick={handleVaccineBaseNavigation}>
                                {t("home-page.vaccine-base-page")}
                                <ArrowForwardIosIcon></ArrowForwardIosIcon>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Flex height="24px"></Flex>
                </Grid>
            </Box>
            <View className="testimonials">
                <Box id="how-does-it-work">
                    <Grid container justifyContent={"center"}>
                        <Text className="h1-header" textAlign={"center"}>
                            {t("home-page.how-the-service-works")}
                        </Text>
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <Text padding={"24px 0px"} maxWidth={"522px"} textAlign={"center"}>
                            {t("home-page.we-work-with-local-healthcare")}
                        </Text>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "block", lg: "flex" },
                        maxWidth: {
                            xs: "100%",
                            md: "50%",
                            lg: "100%",
                        },
                        marginLeft: { xs: "0", md: "25%", lg: "0" },
                    }}>
                    <Grid container justifyContent={"center"}>
                        <TextImageContainer
                            title={titleTest}
                            description={descriptionTest}
                            number={1}
                            img={calendarImg}
                            showBorder={true}></TextImageContainer>
                        <TextImageContainer
                            title={titleGoTo}
                            description={descriptionGoTo}
                            number={2}
                            img={planeImg}
                            showBorder={true}></TextImageContainer>
                        <TextImageContainer
                            title={titleGetCert}
                            description={descriptionGetCert}
                            number={3}
                            img={phoneImg}
                            showBorder={true}></TextImageContainer>
                    </Grid>
                </Box>
            </View>
            <View id="testimonials" className="testimonials_whitebackground">
                <View textAlign="center">
                    <h2>{t("testimonials")}</h2>
                </View>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "none", lg: "flex" },
                    }}>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={2}>
                            <TestimonialsComponent
                                title={user1}
                                description={user1Comment}
                                img={userOneImg}
                                stacked={true}></TestimonialsComponent>
                        </Grid>
                        <Grid item xs={2}>
                            <TestimonialsComponent
                                title={user2}
                                description={user2Comment}
                                img={userTwoImg}
                                stacked={true}></TestimonialsComponent>
                        </Grid>
                        <Grid item xs={2}>
                            <TestimonialsComponent
                                title={user3}
                                description={user3Comment}
                                img={userThreeImg}
                                stacked={true}></TestimonialsComponent>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "flex", lg: "none" },
                    }}>
                    <Grid container>
                        <TestimonialsComponent
                            title={user1}
                            description={user1Comment}
                            img={userOneImg}
                            stacked={false}
                            revert={false}></TestimonialsComponent>
                        <TestimonialsComponent
                            title={user2}
                            description={user2Comment}
                            img={userTwoImg}
                            stacked={false}
                            revert={true}></TestimonialsComponent>
                        <TestimonialsComponent
                            title={user3}
                            description={user3Comment}
                            img={userThreeImg}
                            stacked={false}
                            revert={false}></TestimonialsComponent>
                    </Grid>
                </Box>
            </View>
            <View className="testimonials_whitebackground">
                <Box id="partner_company">
                    <Grid container justifyContent={"center"}>
                        <Text className="h1-header" textAlign={"center"}>
                            {t("vaccine-page.partner-company")}
                        </Text>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: { xs: "block", lg: "flex" },
                        maxWidth: {
                            xs: "100%",
                            md: "50%",
                            lg: "100%",
                        },
                        marginLeft: { xs: "0", md: "25%", lg: "0" },
                        marginTop: { xs: "1em", md: "3em" },
                    }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} lg={3}>
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <img src={company1} srcSet={company1} alt={company1} loading="lazy" />
                            </View>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <img src={company1} srcSet={company2} alt={company1} loading="lazy" />{" "}
                            </View>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <img src={company1} srcSet={company3} alt={company1} loading="lazy" />{" "}
                            </View>
                        </Grid>
                    </Grid>
                </Box>
            </View>

            <Box sx={{ flexGrow: 1 }} className="testimonials">
                <Text className="h1-xs-header" textAlign="center">
                    {t("home-page.book-COVID-test")}
                </Text>

                {officesExist ? (
                    <View id="test-providers">
                        <OfficeContent companies={offices}></OfficeContent>
                    </View>
                ) : (
                    <View id="test-providers">
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} lg={6} xl={4} id="what-do-we-do" alignItems="center">
                                <Text className="h1-xs-header" textAlign="center">
                                    Loading ...
                                </Text>
                            </Grid>
                        </Grid>
                    </View>
                )}

                <View margin="24px 0px" letterSpacing="0.02em" fontSize="14px" lineHeight="18px">
                    <Text className="h1-xs-header" textAlign="center">
                        {t("home-page.locations-overview")}
                    </Text>

                    <View id="mapid-home" padding={"24px 0px"}>
                        <MapContainer center={[64.03, 24.99]} zoom={5.2} height="100%">
                            <TileLayer
                                attribution=""
                                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                            />
                            {mappins(offices)}
                        </MapContainer>
                    </View>
                </View>
            </Box>

            <View padding="24px">
                <Text className="h2-header" textAlign={"center"}>
                    {t("home-page.check-latest-travel-rules")}
                </Text>
                <Grid container justifyContent={"center"}>
                    <Image src={compassGif} alt="" height={"auto"} width="265px"></Image>
                </Grid>
                <View height="23px"></View>
                <Grid container justifyContent={"center"}>
                    <Button
                        onClick={handleCheckTravelRules}
                        className="primary-button"
                        textAlign={"center"}
                        maxWidth={"360px"}>
                        {t("home-page.buttons.travel-rules")}
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </Button>
                </Grid>
                <View height="33px"></View>
            </View>

            <View margin="40px 24px" letterSpacing="0.02em" fontSize="14px" lineHeight="18px">
                <Text className="h2-header" textAlign={"center"}>
                    {t("home-page.faq-header")}
                </Text>
                <Grid container justifyContent={"center"}>
                    <Box
                        maxWidth={"800px"}
                        sx={{
                            display: { xs: "flex" },
                            flexDirection: "column",
                        }}>
                        {faqComponents}
                    </Box>
                </Grid>
            </View>

            <View padding="24px">
                <Text className="h2-header" textAlign={"center"}>
                    {t("home-page.health-services")}
                </Text>
                <Grid container justifyContent={"center"}>
                    <Button
                        textAlign={"center"}
                        maxWidth={"360px"}
                        className="third-button"
                        onClick={() => {
                            handleNavigation("/contact-us");
                        }}
                        margin="24px 0px">
                        {t("home-page.list-health-services")}
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </Button>
                </Grid>
            </View>

            <View id="contact" className="contact display-flex-to-grid" gap="24px">
                <Button
                    className="primary-button-blue"
                    onClick={() => {
                        handleNavigation("/contact-us");
                    }}>
                    {t("home-page.buttons.contact")}
                </Button>
                <Button
                    className="primary-button-blue"
                    onClick={() => {
                        handleNavigation("/products");
                    }}>
                    {t("home-page.buttons.products")}
                </Button>
            </View>

            <View margin="40px 24px" letterSpacing="0.02em" fontSize="14px" lineHeight="18px" textAlign="center">
                <Text className="security-info">
                    {t("home-page.security-info")}
                    <a href="/privacy-policy">{t("home-page.privacy-policy")}</a>
                </Text>
                <Flex height="24px"></Flex>
                <Text>{t("home-page.all-rights")}</Text>
                <Text>{t("home-page.copyright")}</Text>
            </View>

            <View className="App-footer">
                <Grid className="powered-by display-flex">
                    <Image margin="5px" src={poweredByImg} alt="Avc-logo"></Image>
                    <Image margin="5px" src={finImg} alt="FIN-logo"></Image>
                </Grid>
            </View>
        </View>
    );
}

HomePage.defaultProps = {
    //items
};

export default HomePage;
