import React, { useState } from "react";

import ReactiveNavBar from "../components/Navigation/ReactiveNavBar";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import logger from "../logger";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import framepic1 from "../assets/img/onboarding/Medical-services.png";
import framepic2 from "../assets/img/onboarding/New-revenue.png";
import framepic3 from "../assets/img/onboarding/Traveller.png";
import company1 from "../assets/img/company/company-Aava-Logo.png";
import company2 from "../assets/img/company/company-9lives-logo.png";
import company3 from "../assets/img/company/company-Medipulssi-logo.png";
import company4 from "../assets/img/company/company-StadsKliniek.png";
import testimonialicon from "../assets/img/homepage2/testimonials/Testi-icon.svg";
import partner1 from "../assets/img/company/partner-SMAL-logo.png";
import partner2 from "../assets/img/company/partner-ANVR.png";
import social1 from "../assets/img/homepage2/social/linkedIn_S.png";
import social2 from "../assets/img/homepage2/social/instagram_S.png";
import social3 from "../assets/img/homepage2/social/facebook_S.png";
import social4 from "../assets/img/homepage2/social/twitter_S.png";
import poweredByImg from "../assets/img/powered_by.svg";
import finImg from "../assets/img/made_in_finland.svg";
import checkmark from "../assets/img/onboarding/check_circle_outline.svg";
import PageFooter from "../components/Footer/PageFooter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GroupedSelect from "../components/select/groupselect";
import { Helmet } from "react-helmet";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});
function ErrorPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [content, setContent] = useState("business");

    const handleCheckTravelRules = () => {
        try {
            sessionStorage.setItem("vaccine", "");
            sessionStorage.setItem("vaccinePrice", "0");
            sessionStorage.setItem("vaccineType", "");
            navigate(t("public-links.travel-rules"));
        } catch (error) {
            alert("Failed to set items to storage: " + error);
        }
    };
    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleClick = () => {
        navigate("/travel-rules");
    };

    const handleChange = (e) => {
        logger.debug(e);
        //console.log(e);
    };

    const handleRadioButtonChange = (e) => {
        logger.debug(e.target.value);
        //console.log(e.target.value);
        setContent(e.target.value);
    };

    const menuItems = [] as JSX.Element[];
    menuItems.push(
        <MenuItem key={"provider-" + 1} value={"aava"}>
            Aava contact info
        </MenuItem>
    );

    return (
        <View>
            <Helmet>
                <title>Error - Page doesn't exist | Health4Travel</title>
            </Helmet>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <ReactiveNavBar />
                    </Grid>
                    <View className="search-menu dark-background">
                        <div className="frame-main-header2">
                            <div className="frame-551">
                                <Text className="text-main-title">Travel stress-free book medical services easily</Text>
                            </div>
                            <div className="frame-552">
                                <div className="text-main-title2">
                                    Book an appointment with one of our trusted medical service providers near you.
                                </div>
                            </div>
                            <div className="frame-553">
                                <Text className="text-main-title3">Find a medical service</Text>
                            </div>
                        </div>
                        <div className="frame-main-header2">
                            <ThemeProvider theme={darkTheme}>
                                <GroupedSelect />
                            </ThemeProvider>
                        </div>
                    </View>

                    <View className="homepage-session">
                        <div className="homepage-frame-session-text-session">
                            <div className="homepage-frame-session-title">
                                <Text className="homepage-session-title-text">Page not found</Text>
                            </div>
                        </div>
                    </View>

                    {/*<Grid item xs={12}>*/}
                    {/*    <View lineHeight="0">*/}
                    {/*        <ImageContainer img={imgSrc}></ImageContainer>*/}
                    {/*    </View>*/}
                    {/*</Grid>*/}

                    {/*    <Grid item xs={12}>*/}
                    {/*        <View margin="24px 24px">*/}
                    {/*            <Text className="latest-travel-header">*/}
                    {/*                {t("home-page.plan-your-travels")}*/}
                    {/*                <Box*/}
                    {/*                    sx={{*/}
                    {/*                        display: { xs: "none", sm: "block" },*/}
                    {/*                    }}*/}
                    {/*                    lineHeight="30%">*/}
                    {/*                    <br />*/}
                    {/*                </Box>*/}
                    {/*                {t("home-page.book-a-test-easily")}*/}
                    {/*            </Text>*/}
                    {/*            <Flex height="24px"></Flex>*/}
                    {/*            <Text className="latest-travel-info">{t("home-page.plan-your-travel-info")}</Text>*/}
                    {/*            <Flex height="24px"></Flex>*/}
                    {/*        </View>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid container spacing={1} justifyContent={"center"} alignItems={"center"}>*/}
                    {/*        <Grid item xs={5} lg={3} display={"flex"} justifyContent={"flex-end"}>*/}
                    {/*            <Button*/}
                    {/*                width="100%"*/}
                    {/*                onClick={handleCheckTravelRules}*/}
                    {/*                className="primary-button travel-rules">*/}
                    {/*                {t("home-page.travel-rules")}*/}
                    {/*                <ArrowForwardIosIcon></ArrowForwardIosIcon>*/}
                    {/*            </Button>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={5} lg={3}>*/}
                    {/*            <a href="#test-providers">*/}
                    {/*                <Button width="100%" className="primary-button book-test">*/}
                    {/*                    {t("home-page.book-test")}*/}
                    {/*                    <ArrowForwardIosIcon></ArrowForwardIosIcon>*/}
                    {/*                </Button>*/}
                    {/*            </a>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={5} lg={3}>*/}
                    {/*            <Button*/}
                    {/*                width="100%"*/}
                    {/*                className="primary-button book-test"*/}
                    {/*                onClick={handleVaccineBaseNavigation}>*/}
                    {/*                {t("home-page.vaccine-base-page")}*/}
                    {/*                <ArrowForwardIosIcon></ArrowForwardIosIcon>*/}
                    {/*            </Button>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Flex height="24px"></Flex>*/}
                </Grid>
            </Box>

            <PageFooter></PageFooter>
        </View>

        // <View>
        //     <Grid item xs={12}>
        //         <ReactiveNavBar />
        //     </Grid>
        //     <View lineHeight="0">
        //         <ImageContainer img={doctorsImg}></ImageContainer>
        //     </View>
        //     <Flex height={"36px"}></Flex>
        //     <Grid container spacing={0} alignItems="center" justifyContent="center" className="radio-toolbar">
        //         <Grid item xs={4} md={3} alignItems="center">
        //             <Grid container justifyContent="center" alignItems="center">
        //                 <Flex key={"radioFlex" + 1} margin="0px 8px 8px 8px" width={"80%"}>
        //                     <input
        //                         key={"radio" + 1}
        //                         type="radio"
        //                         id={"1"}
        //                         name="radioContent"
        //                         value={"travelers"}
        //                         onChange={handleRadioButtonChange}
        //                         width={"100%"}
        //                     />
        //                     <label key={"radioLabel" + 1} htmlFor={"1"}>
        //                         {t("contact-us-page.travelers")}
        //                     </label>
        //                 </Flex>
        //             </Grid>
        //         </Grid>
        //         <Grid item xs={4} md={3}>
        //             <Grid container justifyContent="center" alignItems="center">
        //                 <Flex key={"radioFlex" + 2} margin="0px 8px 8px 8px" width={"80%"}>
        //                     <input
        //                         key={"radio" + 2}
        //                         type="radio"
        //                         id={"2"}
        //                         defaultChecked
        //                         name="radioContent"
        //                         value={"business"}
        //                         onChange={handleRadioButtonChange}
        //                     />
        //                     <label key={"radioLabel" + 2} htmlFor={"2"}>
        //                         {t("contact-us-page.business")}
        //                     </label>
        //                 </Flex>
        //             </Grid>
        //         </Grid>
        //     </Grid>
        //     <Flex height={"36px"}></Flex>
        //     <Grid container justifyContent={"center"} p={2}>
        //         <Grid item xs={12} md={10} lg={8}>
        //             {/* Travelers */}
        //             {content === "travelers" && (
        //                 <TravelersContactContent
        //                     menuitems={menuItems}
        //                     onChange={handleChange}></TravelersContactContent>
        //             )}
        //
        //             {/* Business */}
        //             {content === "business" && <BusinessContactContent></BusinessContactContent>}
        //         </Grid>
        //     </Grid>
        //     <View className="App-footer">
        //         <Grid className="powered-by display-flex">
        //             <Image margin="5px" src={poweredByImg} alt="Avc-logo"></Image>
        //             <Image margin="5px" src={finImg} alt="FIN-logo"></Image>
        //         </Grid>
        //     </View>
        // </View>
    );
}

export default ErrorPage;
