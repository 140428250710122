import { API, Auth } from "aws-amplify";
import logger from "../logger";

const apiPath = "avc-api";

//Get session id from api request checkout-session.
//Use session.id to redirect to checkout aka paymet
//TODO: Fetch price id from stripe and add it to body.
async function fetchSession(params: any) {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const apiEndpoint = "/checkout-session";
    const body = {
        quantity: params.quantity,
        path: params.path,
        priceId: params.priceId,
        email: params.email,
        orderNumber: params.orderNumber,
        //client_reference_id: 'UniqueString',
    };

    try {
        const session = await API.post(apiPath, apiEndpoint, {
            body,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
        return session;
    } catch (error) {
        logger.error("Error while posting stripe data: " + error);
        //console.error('Error while posting stripe data: ' + error)
    }
}

async function retrieveSession(params: any) {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const apiEndpoint = "/retrieve-payment-status";
    const body = {
        sessionId: params.sessionId,
    };

    try {
        const session = await API.post(apiPath, apiEndpoint, {
            body,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
        return session;
    } catch (error) {
        logger.error("Error while posting stripe data: " + error);
        //console.error('Error while posting stripe data: ' + error)
        return error;
    }
}

export { fetchSession, retrieveSession };
