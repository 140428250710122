import React, { useContext, useEffect, useState } from "react";

import NavLinks from "./components/Navigation/MobileNavLinks";

import "./utils/i18n";

import AppRoutes from "./utils/AppRoutes";
import { ConfigContext, NavContext, TravelFormContext, OrderDataContext } from "./context/Config";

import styled, { ThemeProvider } from "styled-components";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import WebFont from "webfontloader";
import { GlobalStyles } from "./theme/GlobalStyles";
import { useTheme } from "./theme/useTheme";
import { IOrderData } from "./types/Interfaces";
import _ from "lodash";
import { getFromSessionStorage } from "./utils/LocalStorageUtils";
import { config } from "./configurations/config";
import { configureAmplify } from "./utils/amplify/configure";
import { initializeGA } from "./utils/google-analytics";

const Container = styled.div`
    margin: 0px auto 0px auto;
`;

configureAmplify();

initializeGA();

const initialFormState = {
    travelFrom: "",
    travelTo: "",
    nationality: "",
    residency: "",
    vaccinationStatus: "",
};

function App() {
    const navData = useContext(NavContext);

    const [travelForm, setTravelForm] = useState(initialFormState); //Iata confit
    const [contextOrder, setContextOrder] = React.useState<IOrderData>({} as IOrderData); //Iata confit

    // 3: Get the selected theme, font list, etc.
    const { theme, themeLoaded, getFonts } = useTheme();
    const [selectedTheme, setSelectedTheme] = useState(theme);

    const [navState, setNavState] = useState({ showNav: false });
    const { showNav } = navState;

    const validateTravelForm = (cloneForm) => {
        const keys = Object.keys(cloneForm);
        const isValid = keys.every((key) => {
            return !_.isEmpty(cloneForm[key]);
        });
        return isValid;
    };

    //Navigation button state
    useEffect(() => {
        setNavState({ showNav });
    }, [showNav, navData.showNav]);

    useEffect(() => {
        if (contextOrder.orders && contextOrder.orders.length > 0) {
            localStorage.setItem("contextOrder", JSON.stringify(contextOrder));
        }
    }, [contextOrder]);

    useEffect(() => {
        if (travelForm && validateTravelForm(travelForm)) {
            sessionStorage.setItem("travelForm", JSON.stringify(travelForm));
        } else {
            const sessionTravelForm = getFromSessionStorage("travelForm");
            if (sessionTravelForm) {
                setTravelForm(sessionTravelForm);
            }
        }
    }, [travelForm]);

    //Set theme
    useEffect(() => {
        setSelectedTheme(theme);
    }, [themeLoaded]);

    // 4: Load all the fonts
    useEffect(() => {
        WebFont.load({
            google: {
                families: getFonts(),
            },
        });
    });

    return (
        <div>
            {themeLoaded && (
                <ThemeProvider theme={selectedTheme}>
                    <MuiThemeProvider theme={createTheme()}>
                        <GlobalStyles />
                        <Container>
                            <NavContext.Provider value={{ navState, setNavState }}>
                                {showNav && (
                                    <div className="overlay">
                                        <NavLinks></NavLinks>
                                    </div>
                                )}
                                <ConfigContext.Provider value={config}>
                                    <TravelFormContext.Provider value={{ travelForm, setTravelForm }}>
                                        <OrderDataContext.Provider value={{ contextOrder, setContextOrder }}>
                                            <AppRoutes />
                                        </OrderDataContext.Provider>
                                    </TravelFormContext.Provider>
                                </ConfigContext.Provider>
                            </NavContext.Provider>
                        </Container>
                    </MuiThemeProvider>
                </ThemeProvider>
            )}
        </div>
    );
}

export default App;
