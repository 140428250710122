import React from "react";
import { Button, Flex, IconLogin, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { responsiveStyles } from "../../theme/GlobalStyles";

export default function LoginButton(props: any) {
    const { overrides: overridesProp, text, ...rest } = props;
    const overrides = { ...overridesProp };
    const { t } = useTranslation();

    function deleteAllCookies() {
        const cookies = document.cookie.split(";");
        localStorage.removeItem("contextOrder");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    const handleloginbuttonclick = () => {
        //save the language settings
        const currentLanguage = localStorage.getItem("language") as string;

        // clean the local storage to have a fresh start
        deleteAllCookies();
        localStorage.clear();
        sessionStorage.clear();

        //restore the langauge settings
        localStorage.setItem("language", currentLanguage);
    };

    return (
        <Button
            width={responsiveStyles.buttons.width}
            alignSelf="center"
            position="relative"
            className="primary-button primary-button-login"
            onClick={handleloginbuttonclick}>
            <Text className="loginButtonText" padding="2px" fontSize={"19px"} margin="0">
                {t("login")}
            </Text>
            <IconLogin
                padding="1px"
                //position= "absolute"
                left="185px"
                //top="17px"
                color="#183A59"></IconLogin>
        </Button>
    );
}
