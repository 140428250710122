import { createContext } from "react";
import { config } from "../configurations/config";
import { INavConfigs, ICustomerDataArray, IOrderDataContext, ITravelFormContext } from "../types/Interfaces";
import CustomerDataJson from "./../utils/mock/CustomerData.json";

const initialNavConfig: INavConfigs = {
    showNav: false,
};

const initialTravelForm: ITravelFormContext = {
    travelForm: {
        travelFrom: "",
        travelTo: "",
        nationality: "",
        residency: "",
        vaccinationStatus: "",
    },
    setTravelForm: () => {},
};

//Orderdata for context provider
const initialOrderConfig: IOrderDataContext = {
    contextOrder: {
        isPaid: false,
        status: "",
        iata: {
            iataCode: "",
            companyId: null,
            companyName: "",
        },
        id: "",
        complete: false,
        userId: "",
        updatedAt: new Date(),
        userName: "",
        createdAt: new Date(),
        bookingDate: "",
        orders: [CustomerDataJson],
        location: {
            location: "",
            locationAddress: "",
            officename: "",
            officecoordinates: "",
        },
        producttype: "",
        productquatity: 0,
    },
    setContextOrder: () => {},
};

//Customerdata in order for context provider
const initialCustomerDataConfig: ICustomerDataArray = {
    customerData: [CustomerDataJson],
    setCustomerData: () => {},
};

const ConfigContext = createContext(config);
const NavContext = createContext(initialNavConfig);
const TravelFormContext = createContext(initialTravelForm);
const CustomerDataContext = createContext(initialCustomerDataConfig);
const OrderDataContext = createContext(initialOrderConfig);

export { ConfigContext, NavContext, TravelFormContext, CustomerDataContext, OrderDataContext };
