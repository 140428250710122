import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import searchmedicon from "../../assets/img/homepage2/search/content-icon-med.svg";
import searchlocationicon from "../../assets/img/homepage2/search/content-icon-location.svg";
import vaccineicon from "../../assets/img/homepage2/search/content-icon-vax-status.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const groupdata = [
    {
        ParentServiceName: "Vaccinations", // the name is not anymore in use due to the multiple language settings
        ParentServiceValue: "vaccinations", // the value is the routing to the page
        ParentServiceItems: [
            {
                ServiceTypeName: "Travel vaccinations",
                ServiceTypeValue: "travelvaccine",
                ServiceTypeItems: [
                    {
                        ProductGroupName: "Hepatitis A",
                        ProductGroupValue: "hepatitis a", // the value is the routing to the page
                    },
                    {
                        ProductGroupName: "Hepatitis B", //name is shown on the page
                        ProductGroupValue: "hepatitis b", // the value is the routing to the page
                    },
                    {
                        ProductGroupName: "Hepatitis", //name is shown on the page
                        ProductGroupValue: "hepatitis", // the value is the routing to the page
                    },

                    {
                        ProductGroupName: "Diphtheria",
                        ProductGroupValue: "Diphtheria",
                    },
                    {
                        ProductGroupName: "Yellow Fever",
                        ProductGroupValue: "yellow fever",
                    },
                    {
                        ProductGroupName: "Meningitis",
                        ProductGroupValue: "meni",
                    },
                    {
                        ProductGroupName: "Typhoid",
                        ProductGroupValue: "typhoid",
                    },
                    {
                        ProductGroupName: "Japanese encephalitis",
                        ProductGroupValue: "ence",
                    },
                    {
                        ProductGroupName: "HPV",
                        ProductGroupValue: "hpv",
                    },
                    {
                        ProductGroupName: "Pneumococcal",
                        ProductGroupValue: "pneumococcal",
                    },
                    {
                        ProductGroupName: "Chicken-pox",
                        ProductGroupValue: "pox",
                    },
                    {
                        ProductGroupName: "Hib",
                        ProductGroupValue: "hib",
                    },
                    {
                        ProductGroupName: "Rabies",
                        ProductGroupValue: "rabies",
                    },
                    {
                        ProductGroupName: "Measles",
                        ProductGroupValue: "measles",
                    },
                    {
                        ProductGroupName: "DTP",
                        ProductGroupValue: "dtp",
                    },
                ],
            },
            {
                ServiceTypeName: "Seasonal vaccinations",
                ServiceTypeValue: "seasonalvaccine",
                ServiceTypeItems: [
                    {
                        ProductGroupName: "influenza",
                        ProductGroupValue: "influenza",
                    },
                    {
                        ProductGroupName: "Tick TBE",
                        ProductGroupValue: " tbe", //there is a space in here
                    },
                    {
                        ProductGroupName: "COVID-19 booster",
                        ProductGroupValue: "covid19booster",
                    },
                ],
            },
        ],
    },
    {
        ParentServiceName: "Medical tests",
        ParentServiceValue: "medicaltests",
        ParentServiceItems: [
            {
                ServiceTypeName: "STD tests",
                ServiceTypeValue: "stdtests",
                ServiceTypeItems: [
                    {
                        ProductGroupName: "HIV test",
                        ProductGroupValue: "hiv",
                    },
                    {
                        ProductGroupName: "Hepatitis test",
                        ProductGroupValue: "stdtest-hepatitis",
                    },
                    {
                        ProductGroupName: "Bloedtest",
                        ProductGroupValue: "bloedtest",
                    },
                    {
                        ProductGroupName: "Chlamydia test",
                        ProductGroupValue: "Chlamydia",
                    },
                    {
                        ProductGroupName: "STD test",
                        ProductGroupValue: "std",
                    },
                ],
            },
            {
                ServiceTypeName: "COVID-19 tests",
                ServiceTypeValue: "covid19tests",
                ServiceTypeItems: [
                    {
                        ProductGroupName: "Antigen rapid test",
                        ProductGroupValue: "anti",
                    },
                    {
                        ProductGroupName: "PCR test + travel certificate",
                        ProductGroupValue: "pcr",
                    },
                ],
            },
        ],
    },
    {
        ParentServiceName: "Doctors",
        ParentServiceValue: "doctors",
        ParentServiceItems: [
            {
                ServiceTypeName: "Doctor Appointments",
                ServiceTypeValue: "doctorappointment",
                ServiceTypeItems: [
                    {
                        ProductGroupName: "Appointment HIV-rapid test",
                        ProductGroupValue: "HIV-rapid",
                    },
                    {
                        ProductGroupName: "Appointment STD test",
                        ProductGroupValue: "stdtest",
                    },
                    {
                        ProductGroupName: "Appointment 2 people",
                        ProductGroupValue: "consultation",
                    },
                    {
                        ProductGroupName: "STD treatment",
                        ProductGroupValue: "treatment",
                    },
                ],
            },
        ],
    },
];

export function getServiceTypeItemsArray(ParentServiceValue: string, ServiceTypeValue: string) {
    if (groupdata !== undefined) {
        //find travel vaccinations in groupdata
        const parentgroupobj = groupdata.find((item) => item.ParentServiceValue === ParentServiceValue);
        //find travel vaccinations in travelvaccinations
        const groupobj = parentgroupobj?.ParentServiceItems.find((item) => item.ServiceTypeValue === ServiceTypeValue);
        //find travel vaccinations in travelvaccinationsitems
        const groupitemsobj = groupobj?.ServiceTypeItems;
        return groupitemsobj;
    }
}

let ProductGroupData: any[] = [];
export default function GroupedSelect() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [ServiceType, setServiceType] = React.useState(sessionStorage.getItem("ServiceType") as string);
    // console.log("the service type is:");
    // console.log(ServiceType);

    const [ProductGroup, setProductGroup] = React.useState(sessionStorage.getItem("ProductGroup") as string);
    // console.log("the product group type is:");
    // console.log(ProductGroupType);

    const [ServiceLocation, setServiceLocation] = React.useState(sessionStorage.getItem("ServiceLocation") as string);
    // console.log("the service location is:");
    // console.log(ServiceLocation);

    function findParentServiceValue(ServiceTypeValueInput: string) {
        let parentgroupvalue = "";
        groupdata.map((parentgroupobj) => {
            parentgroupobj.ParentServiceItems.map((groupobj) => {
                if (groupobj.ServiceTypeValue === ServiceTypeValueInput) {
                    parentgroupvalue = parentgroupobj.ParentServiceValue;
                    return parentgroupvalue;
                }
            });
        });
        return parentgroupvalue;
    }

    const handleChangeServicetype = (event: SelectChangeEvent) => {
        setServiceType(event.target.value as string);
        setProductGroup("");
        groupdata.filter((parentgroupobj) => {
            //todo this is problematic
            parentgroupobj.ParentServiceItems.map((groupobj) => {
                if (groupobj.ServiceTypeValue === event.target.value) {
                    ProductGroupData = [];
                    ProductGroupData.push(groupobj);
                    return groupobj;
                }
            });
        });
        // console.log(ProductGroupData);
        sessionStorage.setItem("ProductGroupData", JSON.stringify(ProductGroupData));
        sessionStorage.setItem("ParentServiceType", findParentServiceValue(event.target.value as string));
    };

    if (sessionStorage.getItem("ProductGroupData") !== "" && sessionStorage.getItem("ProductGroupData") !== null) {
        ProductGroupData = JSON.parse(sessionStorage.getItem("ProductGroupData") as string);
    }

    const handleChangeProductGrouptype = (event: SelectChangeEvent) => {
        setProductGroup(event.target.value as string);
    };

    const handleChangeServiceLocation = (event: SelectChangeEvent) => {
        setServiceLocation(event.target.value as string);
    };

    const handleSearchbutton = () => {
        console.log("searching for service"); //pass the value to proceed
        console.log(ServiceType);
        console.log(ServiceLocation);
        sessionStorage.setItem("ProductGroup", ProductGroup);
        sessionStorage.setItem("ServiceType", ServiceType);
        sessionStorage.setItem("ServiceLocation", ServiceLocation);
        const currentLanguageCode = localStorage.getItem("language") ? localStorage.getItem("language") : "en";
        if (ServiceType !== "null" && ServiceType !== "") {
            if (ProductGroup === "") {
                // navigate("/" + currentLanguageCode + "/" + ServiceType); //todo make the language settings dynamic
                navigate(t("public-links." + ServiceType));
            } else {
                //if now the url is /booking , then refresh the page, if not , go to /booking
                if (window.location.pathname.includes("/booking")) {
                    window.location.reload();
                } else {
                    navigate(t("public-links.booking-landing-page"));
                }
            }
        } else {
            navigate("/");
        }
    };

    return (
        <View className="frame-search ">
            <View className="frame-search-item">
                <div className="frame-search-icon">
                    <img src={searchmedicon} srcSet={searchmedicon} alt={searchmedicon} loading="lazy" />
                </div>

                <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel htmlFor="grouped-native-select">
                        {t("home-page2.search-bar-groupselect-text1")}
                    </InputLabel>
                    <Select
                        value={ServiceType}
                        native
                        defaultValue=""
                        id="grouped-native-select"
                        label={t("home-page2.search-bar-groupselect-text1")}
                        onChange={handleChangeServicetype}>
                        <option aria-label="None" value="" />
                        {/*<optgroup label="Travel vaccinations">*/}
                        {/*    <option value={"hepatitis"}>Hepatitis</option>*/}
                        {/*    <option value={"yellowfever"}>Yellow Fever</option>*/}
                        {/*</optgroup>*/}
                        {groupdata.map((parentgroupobj, index) => (
                            <optgroup label={t("grouppages." + parentgroupobj.ParentServiceValue)}>
                                {parentgroupobj.ParentServiceItems.map((groupitem, index) => (
                                    <option value={groupitem.ServiceTypeValue}>
                                        {t("grouppages." + groupitem.ServiceTypeValue)}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </Select>
                </FormControl>
            </View>
            {ProductGroupData.length ? (
                <View className="frame-search-item">
                    <div className="frame-search-icon">
                        <img src={vaccineicon} srcSet={vaccineicon} alt={vaccineicon} loading="lazy" />
                    </div>
                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                        <InputLabel htmlFor="grouped-native-select">
                            {t("home-page2.search-bar-groupselect-text2")}
                        </InputLabel>
                        <Select
                            value={ProductGroup}
                            native
                            defaultValue=""
                            id="grouped-native-select"
                            label="Choose a product"
                            onChange={handleChangeProductGrouptype}>
                            <option aria-label="None" value="" />
                            {ProductGroupData.map((groupitemobj, index) => (
                                <optgroup label={groupitemobj.ServiceTypeName}>
                                    {groupitemobj.ServiceTypeItems.map((groupitemitem, index) => (
                                        <option value={groupitemitem.ProductGroupValue}>
                                            {t("grouppages." + groupitemitem.ProductGroupValue)}
                                            {}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Select>
                    </FormControl>
                </View>
            ) : (
                <div></div>
            )}
            <View className="frame-search-item">
                <div className="frame-search-icon">
                    <img src={searchlocationicon} srcSet={searchlocationicon} alt={searchlocationicon} loading="lazy" />
                </div>
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel htmlFor="grouped-native-select">
                        {t("home-page2.search-bar-groupselect-text3")}
                    </InputLabel>
                    <Select
                        native
                        defaultValue=""
                        value={ServiceLocation}
                        id="grouped-native-select"
                        label="Choose a country"
                        onChange={handleChangeServiceLocation}>
                        <option aria-label="None" value="" />
                        <option value={"all"}>All</option>
                        <optgroup label="Europe">
                            <option value={"fi"}>Finland</option>
                            <option value={"nl"}>Netherlands</option>
                        </optgroup>
                    </Select>
                </FormControl>
            </View>
            <Flex justifyContent="center" width="100px">
                <Button
                    display="flex"
                    id={"product"}
                    className="pointer primary-button-sm"
                    onClick={handleSearchbutton}
                    justifyContent="center"
                    padding="10px 15px"
                    margin="10px 15px"
                    alignItems="center"
                    border="0px"
                    width="100%"
                    maxHeight={"100px"}
                    minHeight={"30px"}
                    // disabled={""}
                >
                    <Text
                        id={"product"}
                        className="vaccine-box-texts"
                        fontSize="14px"
                        fontWeight="900"
                        // font-weight="bold"
                        lineHeight="20px"
                        textAlign="center"
                        // display="flex"
                        width="70px"
                        position=""
                        left="243px"
                        top="30px"
                        padding="0px 0px 0px 0px"
                        children={t("home-page2.search-bar-groupselect-find-button")}></Text>
                    <View height="24px">
                        <Icon
                            id={"product"}
                            className="vaccine-box-texts"
                            ariaLabel=""
                            width="11px"
                            height="14px"
                            pathData="M11.357 9.97376L2.51422 0.345645C2.3097 0.122779 2.03667 0 1.74556 0C1.45444 0 1.18142 0.122779 0.9769 0.345645L0.325687 1.05453C-0.0980614 1.51644 -0.0980614 2.26719 0.325687 2.7284L7.75122 10.8135L0.317448 18.9076C0.112924 19.1305 0 19.4275 0 19.7443C0 20.0615 0.112924 20.3586 0.317448 20.5816L0.96866 21.2903C1.17335 21.5132 1.44621 21.636 1.73732 21.636C2.02844 21.636 2.30146 21.5132 2.50598 21.2903L11.357 11.6534C11.5621 11.4299 11.6747 11.1314 11.674 10.814C11.6747 10.4955 11.5621 10.1971 11.357 9.97376Z"
                            viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 11.67401123046875,
                                height: 21.635986328125,
                            }}></Icon>
                    </View>
                </Button>
            </Flex>
        </View>
    );
}
