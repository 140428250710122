import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { View } from "@aws-amplify/ui-react";
import VaccinesIcon from "@mui/icons-material/Vaccines";

export default function ContenticonPCRtest(props: any) {
    const { overrides: overridesProp, ...rest } = props;
    const overrides = { ...overridesProp };
    return (
        <View
            width="34px"
            height="34px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...rest}
            {...getOverrideProps(overrides, "View")}>
            <VaccinesIcon
                className="icon-dark"
                sx={{ height: "34px", width: "34px", padding: "8px", borderRadius: "28px" }}></VaccinesIcon>
        </View>
    );
}
