import _ from "lodash";

export const getOrderDataFromLocalStorage = () => {
    try {
        const localStorageData = localStorage.getItem("contextOrder");
        if (localStorageData) {
            try {
                const localStorageDataObj = JSON.parse(localStorageData);
                return localStorageDataObj;
            } catch (err) {
                alert("Failed to parse orderData: " + err);
                return null;
            }
        }
        return null;
    } catch (error) {
        alert("Error getting orderData: " + error);
        return null;
    }
};

export const getVaccineFromSessionStorage = (key: string) => {
    try {
        const stored = sessionStorage.getItem(key);
        if (!stored) {
            return "";
        }
        return stored;
    } catch (error) {
        alert("Error getting sessiondata: " + error);
        return "";
    }
};

export const getVaccinePriceFromSessionStorage = () => {
    try {
        const stored = sessionStorage.getItem("vaccinePrice");
        if (!stored) {
            return 0;
        }
        return _.toNumber(stored);
    } catch (error) {
        alert("Error getting vp: " + error);
        return 0;
    }
};

export const getFromSessionStorage = (itemName: string) => {
    try {
        const sessionStorageData = sessionStorage.getItem(itemName);
        if (sessionStorageData) {
            try {
                const sessionStorageDataObj = JSON.parse(sessionStorageData);
                return sessionStorageDataObj;
            } catch (err) {
                alert("Error parsing session data: " + err);
                return null;
            }
        }
        return null;
    } catch (error) {
        alert("Error getting session data: " + error);
        return null;
    }
};
