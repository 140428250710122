import _ from "lodash";
import { useEffect, useState } from "react";
import { View, Text, Flex, Button, TextField } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import "@stripe/stripe-js";

import SpinLoader from "../Loaders/SpinLoader";
import { formatTotalPrice } from "./../../utils/NumberUtils";
import { getPriceId } from "./../../utils/StripeUtils";
import { fetchSession } from "./../../services/StripeService";
import { Alert } from "@mui/material";

const stripe_pk = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY : "";
const stripePromise = loadStripe(stripe_pk);

const Checkout = (props: any) => {
    const currentPath = window.location.origin;

    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(props.quantity);
    const [amount, setAmount] = useState(props.amount);
    const [isLoading, setIsLoading] = useState(false);
    const email = props.email;
    const orderNumber = props.orderNumber;
    const hasEmailAndOrderNumber = !_.isEmpty(email) && !_.isEmpty(orderNumber);

    let priceId = getPriceId(props.vaccineType);

    if (priceId === undefined) {
        if (process.env.REACT_APP_ENV == "prod") {
            // fix the problem taht some of the price id is located locally in the app , while some of them are in the database 1
            priceId = sessionStorage.getItem("priceid");
            console.log("how about now");
            console.log(priceId);
        } else {
            priceId = "price_1LDxhVDoHTi609zMOmsg0jnl"; //dev price id
        }
    }
    const redirectToCheckout = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (email && orderNumber) {
            try {
                const params = {
                    quantity: quantityProp,
                    priceId: priceId,
                    path: currentPath,
                    email: email,
                    orderNumber: orderNumber,
                };
                const session = await fetchSession(params);
                const sessionId = session.id;
                const stripe = await stripePromise;
                if (stripe) {
                    setIsLoading(false);
                    stripe.redirectToCheckout({ sessionId });
                }
            } catch (error) {
                setIsLoading(false);
                alert(error);
            }
        }
    };

    useEffect(() => {
        function fetchConfig() {
            if (amountProp && amountProp > 0) {
                const totalAmount = amountProp;
                setAmount(totalAmount);
            }
        }

        fetchConfig();
    }, []);

    const amountProp = amount && amount > 0 ? amount : props.amount;
    const quantityProp = quantity && quantity > 0 ? quantity : props.quantity;

    return (
        <View className="sr-root">
            <Grid className="sr-main">
                <section className="container">
                    <form action="/checkout" method="POST">
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            justifyContent={"center"}
                            alignItems={"center"}
                            width={"100%"}>
                            <Grid item xs={12}>
                                <View className="quantity-setter">
                                    <Text fontSize="12px" fontWeight="bold" letterSpacing="0.02em">
                                        {t("payment-page.total")}
                                    </Text>
                                    <Text fontSize="20px" fontWeight="bold" letterSpacing="0.02em">
                                        {formatTotalPrice({
                                            amount: amountProp,
                                            currency: "EUR",
                                            quantity: quantityProp,
                                        })}
                                    </Text>
                                    <TextField
                                        fontSize="20px"
                                        fontWeight="bold"
                                        letterSpacing="0.02em"
                                        type="number"
                                        id="quantity-input"
                                        value={quantityProp}
                                        name="quantity"
                                        readOnly
                                        label=""
                                        aria-readonly
                                        border="none"
                                        className="hidden"></TextField>
                                </View>
                            </Grid>
                            <Grid item xs={12} width="90%">
                                <Button
                                    isDisabled={isLoading || !hasEmailAndOrderNumber}
                                    className="primary-button"
                                    role="link"
                                    id="submit"
                                    type="submit"
                                    onClick={redirectToCheckout}
                                    alignSelf={"center"}
                                    width={"100%"}>
                                    {isLoading && <SpinLoader width={20} height={20}></SpinLoader>}
                                    {!isLoading && t("payment-page.pay-now")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </section>
                {!hasEmailAndOrderNumber && (
                    <Alert sx={{ width: "100%" }} severity="error">
                        {"Missing: email and order number"}
                    </Alert>
                )}
                <Flex height="11px"></Flex>
                <Text fontSize="11px" letterSpacing="0.2px" lineHeight="12px" color="#506468">
                    {t("payment-page.payment-info")}
                </Text>
            </Grid>
        </View>
    );
};

export default Checkout;
