/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config = {
    aws_project_region: "eu-central-1", //ProjectRegion
    aws_user_pools_id: "eu-central-1_t2IvcQnPf", //UserPoolId
    aws_user_pools_web_client_id: "7c43d636flg2pdbtbaohnf92p3", //UserPoolClientId,
    aws_cognito_identity_pool_id: "eu-central-1:6002975a-0064-4b23-b072-f4a8f1d7b579", //IdentityPoolId
    aws_cognito_username_attributes: ["EMAIL", "PHONE_NUMBER"],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_cloud_logic_custom: [
        {
            name: "avc-api", //apiName
            endpoint: "https://pwxxsogyz6.execute-api.eu-central-1.amazonaws.com/dev", //apiUrl
            region: "eu-central-1", //ProjectRegion
        },
        {
            name: "travel-requirements-api", //apiName
            endpoint: "https://p37ko5f2o1.execute-api.eu-central-1.amazonaws.com/dev", //apiUrl
            region: "eu-central-1", //ProjectRegion
        },
        {
            name: "offices-api", //apiName
            endpoint: "https://lswgqvgjf9.execute-api.eu-central-1.amazonaws.com/dev", //apiUrl
            region: "eu-central-1", //ProjectRegion
        },
        {
            name: "mails-api", //apiName
            endpoint: "https://wz24w4oh0f.execute-api.eu-central-1.amazonaws.com/dev", //apiUrl
            region: "eu-central-1", //ProjectRegion
        },
        {
            name: "calendar-api", //apiName
            endpoint: "https://n2jahnyts6.execute-api.eu-central-1.amazonaws.com/dev/", //apiUrl
            region: "eu-central-1", //ProjectRegion
        },
        {
            name: "calendar-direct-api", //apiName
            endpoint: "https://agenda.onlineafspraken.nl", //apiUrl
            region: "eu-central-1", //ProjectRegion
        },
    ],
    aws_dynamodb_all_tables_region: "eu-central-1", //ProjectRegion,
    aws_dynamodb_table_schemas: [
        {
            tableName: "aavaOrdersTable", //TableName
            region: "eu-central-1", //ProjectRegion
        },
        {
            tableName: "officesTable", //TableName
            region: "eu-central-1", //ProjectRegion
        },
    ],
};

export default config;
