import React from "react";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";
import social1 from "../../assets/img/homepage2/social/linkedIn_S.png";
import social2 from "../../assets/img/homepage2/social/instagram_S.png";
import social3 from "../../assets/img/homepage2/social/facebook_S.png";
import social4 from "../../assets/img/homepage2/social/twitter_S.png";
import poweredByImg from "../../assets/img/powered_by.svg";
import finImg from "../../assets/img/made_in_finland.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import partner3 from "../../assets/img/company/Flag_of_Europe.svg";

export default function PageFooter() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div>
            <View className="testimonials">
                <div className="homepage-session-title">
                    <Text className="homepage-session-title-text">{t("pagefooter.follow-us")}</Text>
                </div>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={2} lg={2}>
                        <a href="https://www.linkedin.com/company/health4travel/" target="_blank">
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <Image
                                    alt={social1}
                                    src={social1}
                                    maxWidth="80px"
                                    width={"80%"}
                                    padding={"0px"}
                                    height="auto"></Image>
                            </View>
                        </a>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                        <a href="https://www.instagram.com/health4travel" target="_blank">
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <Image
                                    alt={social2}
                                    src={social2}
                                    maxWidth="80px"
                                    width={"80%"}
                                    padding={"0px"}
                                    height="auto"></Image>
                            </View>
                        </a>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                        <a href="https://www.facebook.com/health4travel/" target="_blank">
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <Image
                                    alt={social3}
                                    src={social3}
                                    maxWidth="80px"
                                    width={"80%"}
                                    padding={"0px"}
                                    height="auto"></Image>
                            </View>
                        </a>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                        <a href="https://twitter.com/AirportVax" target="_blank">
                            <View margin="10px auto 20px" display="flex" justifyContent={"center"}>
                                <Image
                                    alt={social4}
                                    src={social4}
                                    maxWidth="80px"
                                    width={"80%"}
                                    padding={"0px"}
                                    height="auto"></Image>
                            </View>
                        </a>
                    </Grid>
                </Grid>
            </View>
            <View id="contact" className="contact display-flex-to-grid" gap="24px">
                <Button
                    className="primary-button-blue"
                    onClick={() => {
                        handleNavigation("/contact-us");
                    }}>
                    {t("pagefooter.contact-us")}
                </Button>
                <Button
                    className="primary-button-blue"
                    onClick={() => {
                        handleNavigation("/onboarding");
                    }}>
                    {t("list-business")}
                </Button>
            </View>
            <View margin="40px 24px" letterSpacing="0.02em" fontSize="14px" lineHeight="18px" textAlign="center">
                <Text className="security-info">
                    {t("home-page.security-info")}
                    <a href="/privacy-policy">{t("home-page.privacy-policy")}</a>
                </Text>
                <Flex height="24px"></Flex>
                <Text>{t("home-page.all-rights")}</Text>
                <Text>{t("home-page.copyright")}</Text>
            </View>
            <View className="App-footer">
                <Grid container className="powered-by display-flex" justifyContent={"center"}>
                    <Grid item xs={6} lg={1} display={"flex"} justifyContent={"center"}>
                        {/*<Image margin="5px" src={poweredByImg} alt="Avc-logo"></Image>*/}
                        <Image margin="5px" src={finImg} alt="FIN-logo"></Image>
                    </Grid>
                    <Grid item xs={6} lg={1.5} display={"flex"} justifyContent={"center"}>
                        <Grid container id="what-do-we-do" display={"flex"} justifyContent={"center"}>
                            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                <img src={partner3} srcSet={partner3} alt={partner3} loading="lazy" width="60px" />
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                <Text>
                                    <br />
                                    {t("home-page2.business-partners-text3")}
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </View>
        </div>
    );
}
