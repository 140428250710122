import { Amplify } from "aws-amplify";
import awsExportsDev from "../../aws-exports-dev";
import awsExportsProd from "../../aws-exports-prod";

export const configureAmplify = () => {
    if (process.env.REACT_APP_ENV == "prod") {
        Amplify.configure(awsExportsProd);
    } else {
        Amplify.configure(awsExportsDev);
    }
};
