import React from "react";
const AboutUsPage = React.lazy(() => import("../pages/AboutUsPage"));
const ServicesPage = React.lazy(() => import("../pages/ServicesPage"));
const ProductsPage = React.lazy(() => import("../pages/ProductsPage"));
const ContactUsPage = React.lazy(() => import("../pages/ContactUsPage"));
const OnBoardingPage = React.lazy(() => import("../pages/OnBoardingPage"));
const HomePage = React.lazy(() => import("./../pages/HomePage"));
const HomePage2 = React.lazy(() => import("./../pages/HomePage2"));
const ErrorPage = React.lazy(() => import("./../pages/ErrorPage"));

const VaccineLandingPage = React.lazy(() => import("./../pages/VaccineLandingPage"));
const TravelRulesPage = React.lazy(() => import("./../pages/TravelRulesPage"));
const TravelRulesSummaryPage = React.lazy(() => import("./../pages/TravelRulesSummaryPage"));
const TermsAndConditionsPage = React.lazy(() => import("../pages/TermsAndConditionsPage"));
const BookTestPage = React.lazy(() => import("../pages/BookTestPage"));
const ChoosePeoplePage = React.lazy(() => import("../pages/ChoosePeoplePage"));
const TimeSlotPage = React.lazy(() => import("../pages/TimeSlotPage"));
const CustomerDetailsPage = React.lazy(() => import("../pages/CustomerDetailsPage"));
const SignUpPage = React.lazy(() => import("../pages/SignUpPage"));
const ConfirmPage = React.lazy(() => import("../pages/ConfirmPage"));
const PrivateHomePage = React.lazy(() => import("../pages/PrivateHomePage"));
const LogoutPage = React.lazy(() => import("../pages/LogoutPage"));
const PrivateBookingPage = React.lazy(() => import("../pages/PrivateBookingPage"));
const PrivateViewDetailsPage = React.lazy(() => import("../pages/PrivateViewDetailsPage"));
const PrivateQRCodesPage = React.lazy(() => import("../pages/PrivateQRCodesPage"));
const PrivateCheckInPage = React.lazy(() => import("../pages/PrivateCheckInPage"));
const PrivateCheckInConfirmedPage = React.lazy(() => import("../pages/PrivateCheckInConfirmedPage"));
const PrivatePreparePage = React.lazy(() => import("../pages/PrivatePreparePage"));
const PrivateAirportInfoPage = React.lazy(() => import("../pages/PrivateAirportInfoPage"));
const PrivatePcrInfoPage = React.lazy(() => import("../pages/PrivatePcrInfoPage"));
const PrivateProfilePage = React.lazy(() => import("../pages/PrivateProfilePage"));
const PaymentPage = React.lazy(() => import("../pages/PaymentPage"));
const PaymentSucceedPage = React.lazy(() => import("../pages/PaymentSucceedPage"));
const PrivateResultsPage = React.lazy(() => import("../pages/PrivateResultsPage"));
const PrivateAirportPage = React.lazy(() => import("../pages/PrivateAirportPage"));
const TermsPage = React.lazy(() => import("../pages/TermsPage"));
const PrivacyPolicyPage = React.lazy(() => import("../pages/PrivacyPolicyPage"));
const VaccinesPage = React.lazy(() => import("../pages/VaccinesPage"));

const TravelVaccinePage = React.lazy(() => import("../pages/grouppages/TravelVaccinePage"));
const SeasonalVaccinePage = React.lazy(() => import("../pages/grouppages/SeasonalVaccinePage"));
const COVID19TestsPage = React.lazy(() => import("../pages/grouppages/COVID19TestsPage"));
const STDTestsPage = React.lazy(() => import("../pages/grouppages/STDTestsPage"));
const DoctorAppointmentPage = React.lazy(() => import("../pages/grouppages/DoctorAppointmentPage"));
const BookingLandingPage = React.lazy(() => import("../pages/BookingLandingPage"));

const TickVaccinePage = React.lazy(() => import("../pages/grouppages/TickVaccinePage"));

const COMPONENTS = {
    HomePage: HomePage,
    HomePage2: HomePage2,
    ErrorPage: ErrorPage,
    VaccineLandingPage: VaccineLandingPage,
    ProductsPage: ProductsPage,
    AboutUsPage: AboutUsPage,
    TermsPage: TermsPage,
    PrivacyPolicyPage: PrivacyPolicyPage,
    ServicesPage: ServicesPage,
    ContactUsPage: ContactUsPage,
    OnBoardingPage: OnBoardingPage,
    TravelRulesPage: TravelRulesPage,
    TravelRulesSummaryPage: TravelRulesSummaryPage,
    TermsAndConditionsPage: TermsAndConditionsPage,
    BookTestPage: BookTestPage,
    ChoosePeoplePage: ChoosePeoplePage,
    TimeSlotPage: TimeSlotPage,
    CustomerDetailsPage: CustomerDetailsPage,
    SignUpPage: SignUpPage,
    ConfirmPage: ConfirmPage,
    PrivateHomePage: PrivateHomePage,
    LogoutPage: LogoutPage,
    PrivateBookingPage: PrivateBookingPage,
    PrivateViewDetailsPage: PrivateViewDetailsPage,
    PrivateQRCodesPage: PrivateQRCodesPage,
    PrivateCheckInPage: PrivateCheckInPage,
    PrivateCheckInConfirmedPage: PrivateCheckInConfirmedPage,
    PrivatePreparePage: PrivatePreparePage,
    PrivateAirportInfoPage: PrivateAirportInfoPage,
    PrivatePcrInfoPage: PrivatePcrInfoPage,
    PaymentPage: PaymentPage,
    PaymentSucceedPage: PaymentSucceedPage,
    PrivateProfilePage: PrivateProfilePage,
    PrivateResultsPage: PrivateResultsPage,
    PrivateAirportPage: PrivateAirportPage,
    VaccinesPage: VaccinesPage,
    TravelVaccinePage: TravelVaccinePage,
    SeasonalVaccinePage: SeasonalVaccinePage,
    COVID19TestsPage: COVID19TestsPage,
    STDTestsPage: STDTestsPage,
    DoctorAppointmentPage: DoctorAppointmentPage,
    BookingLandingPage: BookingLandingPage,
    TickVaccinePage: TickVaccinePage,
};

export default COMPONENTS;
