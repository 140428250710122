import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const NavLinks = () => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState<any | null>(null);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);
    const { t } = useTranslation();
    const links = [
        {
            to: t("public-links.travel-rules"),
            name: t("travel-requirements"),
        },
        {
            to: t("public-links.about-us"),
            name: t("about-us"),
        },
        {
            to: t("public-links.services"),
            name: t("services"),
        },
        {
            to: t("public-links.onboarding"),
            name: t("list-your-business"),
        },
        {
            to: t("public-links.contact-us"),
            name: t("contact-us"),
        },
    ];

    return (
        <>
            {links.map((link) => (
                <Button
                    sx={{
                        mp: 1,
                    }}>
                    <Link to={link.to} className={"desktopNavLink" + (url === link.to ? " activeNavLink" : "")}>
                        {link.name}
                    </Link>
                </Button>
            ))}
        </>
    );
};

export default NavLinks;
