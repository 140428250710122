// client request: creating a new todo
import { API } from "aws-amplify";
import logger from "../logger";

const apiPath = "offices-api";

async function getAllOffices() {
    return await API.get(apiPath, "/offices/", {
        headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.REACT_APP_OFFICESAPI_KEY,
        },
    })
        .then((result) => {
            try {
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse getAllOffices response: " + error);
                //console.error('Failed to parse getAllOffices response: ' + error);
            }
        })
        .catch((err) => {
            logger.error("Failed to fetch getAllOffices: " + err);
            //console.log('Failed to fetch getAllOffices: ' + err);
        });
}

async function getOfficesById(id) {
    return await API.get(apiPath, `/offices/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.REACT_APP_OFFICESAPI_KEY,
        },
    })
        .then((result) => {
            try {
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse getOfficesById response: " + error);
                //console.error('Failed to parse getOfficesById response: ' +  error);
            }
        })
        .catch((err) => {
            logger.error("Failed to fetch getOfficesById: " + err);
            //console.log('Failed to fetch getOfficesById: ' + err);
        });
}

export { getAllOffices, getOfficesById };
