import React, { Suspense } from "react";
import COMPONENTS from "../components/componentMapper";

import { useTranslation } from "react-i18next";
import Checkout from "./../components/Stripe/Checkout";

import HomePage from "./../pages/HomePage";

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import PrivateAppRoutes from "./PrivateAppRoutes";
import { privateRoutes, routes } from "./routes";
import { useTrackPageViews } from "./google-analytics/useTrackPageView";
import ErrorPage from "../pages/ErrorPage";

const RoutesHandler = () => {
    useTrackPageViews();
    const { i18n } = useTranslation();
    const location = useLocation();

    const languageByUrl = React.useMemo(() => {
        /** we want to find out if the pathname without backslash is one of the routes from routes and privateroutes */
        const pathname = location.pathname;

        const langPathArray = [...Object.values(routes), ...Object.values(privateRoutes)]
            .map((route) => route.paths)
            .reduce((acc, currentPath) => {
                if (currentPath.fi === currentPath.en) {
                    return [...acc, { path: currentPath.en }];
                }

                const englishPath = { path: currentPath.en, lang: "en" };
                const finnishPath = { path: currentPath.fi, lang: "fi" };
                const chinesePath = { path: currentPath.cn, lang: "cn" };
                const dutchPath = { path: currentPath.nl, lang: "nl" };
                const japanesePath = { path: currentPath.jp, lang: "jp" };
                // note for later: the order of these two lines is important
                // the first one is the default language
                return [...acc, englishPath, finnishPath, chinesePath, dutchPath, japanesePath];
            }, [] as Array<any>);
        /**
         * TODO: Clean up this helper
         * This filters through our routes and determines the language of
         * the specific route
         */
        return langPathArray.find((path) => path.path?.includes(pathname))?.lang || i18n.language;
    }, [location.pathname]);

    React.useEffect(() => {
        i18n.changeLanguage(languageByUrl);
    }, [languageByUrl]);

    return (
        <Routes>
            {Object.values(routes).map(({ paths, component }) => {
                const Component = COMPONENTS[component];
                return (
                    <>
                        {Object.entries(paths).map(([locale, path]) => {
                            return <Route key={locale} path={path} element={<Component />} />;
                        })}
                    </>
                );
            })}
            <Route key="app" path={"/app/*"} element={<PrivateAppRoutes />}></Route>

            <Route path="/success.html" element={<HomePage />}></Route>
            <Route path="/canceled.html" element={<HomePage />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
    );
};

function AppRoutes() {
    return (
        <div className="App-body">
            <Router>
                <Suspense fallback={<h1>Still Loading…</h1>}>
                    <RoutesHandler />
                </Suspense>
            </Router>
        </div>
    );
}

export default AppRoutes;
