import AppBar from "@mui/material/AppBar";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LoginButton from "../Buttons/LoginButton";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import T2TLogo from "./../../assets/img/test2travel-logo.svg";
import H2TLogo from "./../../assets/img/Health4Travel-logo.png";
import { Flex, Image, Link } from "@aws-amplify/ui-react";
import LanguageSelector from "./LanguageSelector";
import NavLinks from "./NavLinks";

export default function DesktopNavBar(props: any) {
    const { overrides: overridesProp, showicon, ...rest } = props;
    const overrides = { ...overridesProp };

    const origin = window.location.origin.toString();

    const logoUrl = useMemo(() => {
        if (origin.includes("test2travel")) {
            return T2TLogo;
        }
        if (origin.includes("health4travel")) {
            return H2TLogo;
        }
        return H2TLogo;
    }, [origin]);

    return (
        <AppBar position="static" className="desktopNavBar">
            <Container maxWidth={false} className="desktopNav">
                <Toolbar disableGutters>
                    <Flex
                        gap="2px"
                        direction="row"
                        height="45px"
                        justifyContent="center"
                        alignItems="center"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="5px 0px 0px 0px"
                        display="block"
                        {...getOverrideProps(overrides, "Flex.Flex[0]")}>
                        {showicon && (
                            <Link
                                href={
                                    "/" +
                                    (localStorage.getItem("language") === "en" ||
                                    (localStorage.getItem("language") as string) === null
                                        ? ""
                                        : localStorage.getItem("language"))
                                }>
                                <Image
                                    alt="Logo"
                                    width="164.95px"
                                    height="54px"
                                    src={logoUrl}
                                    shrink="0"
                                    position="relative"
                                    {...getOverrideProps(overrides, "Flex.Flex[0].Icon[0]")}></Image>
                            </Link>
                        )}
                        {!showicon && <Flex width="100px"></Flex>}
                    </Flex>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "end",
                        }}>
                        <NavLinks />
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                        }}>
                        <a href="/app/home">
                            <LoginButton className="loginButton"></LoginButton>
                        </a>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                        }}>
                        <LanguageSelector></LanguageSelector>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
