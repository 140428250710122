import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "./../../utils/NumberUtils";
import ContenticonPCRtestDark from "../Icon/ContenticonPCRtestDark";
import ContentIconTickVaccine from "../Icon/ContenticonTickVaccine";
import ContentIconVaccine from "../Icon/ContenticonVaccine";
import { getproductlistinfo } from "../../utils/StripeUtils";
import { useTranslation } from "react-i18next";

export default function PriceCard(props: any) {
    const { overrides: overridesProp, ...rest } = props;
    const overrides = { ...overridesProp };
    const navigate = useNavigate();
    const { t } = useTranslation();
    const producttype = props.producttype;
    // let productInfo = getProductInfo(producttype); // this is loading from the local storage
    const productInfo = getproductlistinfo(props.productlist, producttype);
    if (productInfo) {
        const price = productInfo.price;
        const time = productInfo.time;
        const timeUnit = t("time-units." + productInfo.timeUnit);
        const product = productInfo.product;
        const productName = product ? product.split(",")[0] : product;
        const additionalInfo = productInfo.additionalInfo ? t(productInfo.additionalInfo) : "";
        const companyid = props.companyid;
        const companylogo = props.companylogo;
        const productobj = props.productobj;
        const productgroupprice = productInfo.productgroupprice;
        const priceid = productInfo.priceid;

        const formattedPrice = formatPrice({ amount: price, currency: "EUR" });
        let text;
        if (time) {
            text = t("total") + " " + formattedPrice + " (" + time + timeUnit + ")";
        } else {
            text = t("total") + " " + formattedPrice;
        }

        const certificateText = " + " + t("e-certificate");
        const isDisabled = props.disabled;

        const handleVaccineSelection = (e) => {
            //Store vaccine selection to session storage
            if (e.target.id) {
                try {
                    sessionStorage.setItem("vaccine", product); //TODO should have some "vaccine"-id instead of text
                    sessionStorage.setItem("priceid", priceid);
                    sessionStorage.setItem("vaccinePrice", price);
                    sessionStorage.setItem("vendermachinetimeslot", productInfo.vendermachinetimeslot);
                    sessionStorage.setItem("vaccineType", producttype);
                    sessionStorage.setItem("vaccineadditionalinfo", productInfo.additionalInfo);
                    sessionStorage.setItem("productgroupprice", productgroupprice);
                    sessionStorage.setItem("companyid", companyid);
                    sessionStorage.setItem("companylogo", companylogo);
                    sessionStorage.setItem("officeshortcode", props.officeinfo.shortCode);
                    sessionStorage.setItem("officename", props.officeinfo.officeName);
                    sessionStorage.setItem(
                        "officeaddress",
                        props.officeinfo.officeName +
                            ", " +
                            props.officeinfo.address +
                            ", " +
                            props.officeinfo.postalCode +
                            ", " +
                            props.officeinfo.city
                    );
                    sessionStorage.setItem("officecoordinates", props.officeinfo.coordinates);

                    //for stadskliniek calendar
                    sessionStorage.setItem("resourceID", props.officeinfo.resourceID);
                    sessionStorage.setItem("appointmentTypeID", productInfo.appointmentTypeID);

                    navigate("/book-test"); // Change to /checking from / book-test
                } catch (error) {
                    alert("Failed to set products to storage: " + error);
                }
            }
        };

        const isWhichIcon = () => {
            if (productName.includes("tick") || productName.includes("punkki")) {
                return <ContentIconTickVaccine />;
            } else if (productName.includes("PCR") || productName.includes("Antigen")) {
                return <ContenticonPCRtestDark />;
            } else return <ContentIconVaccine />;
        };
        //TODO: isWhichIcon needs to be prepared for dynamic translations.

        //TODO: at the moment rapid test is disabled and sold out text should be shown that's why.
        //We don't know are these tests really sold out. Tehre is no access for that data yet...
        const soldOutText = isDisabled ? <Text color={"red"}>- {t("sold-out")}</Text> : <View></View>;

        return (
            <Flex alignSelf="center" justifyContent="center">
                <View
                    className="vaccine-box-border"
                    width="100%"
                    minHeight="110px"
                    maxHeight="350px"
                    position="relative"
                    margin="10px 0px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...rest}
                    {...getOverrideProps(overrides, "View")}>
                    <Grid container className="vaccine-box">
                        <Grid container justifyContent="center" alignItems="center" direction="row">
                            <Grid item xs={2} sm={2} xl={2}>
                                <Flex alignItems="left" justifyContent={"left"} margin="20px 20px">
                                    {isWhichIcon()}
                                </Flex>
                            </Grid>
                            <Grid item xs={10} sm={7} xl={7}>
                                <Flex display="block" margin="16px 15px 16px 0px" textAlign={"center"}>
                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                        color="rgba(24.000000469386578,58.00000034272671,89.00000229477882,1)"
                                        width="100%"
                                        children={productName}
                                        {...getOverrideProps(overrides, "View.Text[0]")}></Text>
                                    <Text
                                        fontSize="14px"
                                        fontWeight="600"
                                        color="rgba(24.000000469386578,58.00000034272671,89.00000229477882,1)"
                                        width="100%"
                                        children={additionalInfo}></Text>
                                    <Text
                                        width="100%"
                                        fontSize="16px"
                                        fontWeight="400"
                                        color="rgba(24.000000469386578,58.00000034272671,89.00000229477882,1)"
                                        children={text}
                                        {...getOverrideProps(overrides, "View.Text[1]")}></Text>
                                    {/*<Text>{certificateText}</Text>*/}
                                    {soldOutText}
                                </Flex>
                            </Grid>
                            <Grid item xs={8} sm={3} xl={3}>
                                <Flex justifyContent="right" alignItems="right" width="100%">
                                    <Button
                                        display="flex"
                                        id={product}
                                        className="pointer primary-button-sm"
                                        onClick={handleVaccineSelection}
                                        justifyContent="center"
                                        padding="10px 15px"
                                        margin="10px 15px"
                                        alignItems="center"
                                        border="0px"
                                        width="100%"
                                        maxHeight={"100px"}
                                        minHeight={"30px"}
                                        disabled={isDisabled}>
                                        <Text
                                            id={product}
                                            className="vaccine-box-texts"
                                            fontSize="14px"
                                            fontWeight="600"
                                            lineHeight="20px"
                                            textAlign="left"
                                            display="flex"
                                            width="70px"
                                            position=""
                                            left="243px"
                                            top="30px"
                                            padding="0px 0px 0px 0px"
                                            children={t("book-now")}
                                            {...getOverrideProps(overrides, "View.Text[2]")}></Text>
                                        <View height="24px">
                                            <Icon
                                                id={product}
                                                className="vaccine-box-texts"
                                                ariaLabel=""
                                                width="11px"
                                                height="14px"
                                                pathData="M11.357 9.97376L2.51422 0.345645C2.3097 0.122779 2.03667 0 1.74556 0C1.45444 0 1.18142 0.122779 0.9769 0.345645L0.325687 1.05453C-0.0980614 1.51644 -0.0980614 2.26719 0.325687 2.7284L7.75122 10.8135L0.317448 18.9076C0.112924 19.1305 0 19.4275 0 19.7443C0 20.0615 0.112924 20.3586 0.317448 20.5816L0.96866 21.2903C1.17335 21.5132 1.44621 21.636 1.73732 21.636C2.02844 21.636 2.30146 21.5132 2.50598 21.2903L11.357 11.6534C11.5621 11.4299 11.6747 11.1314 11.674 10.814C11.6747 10.4955 11.5621 10.1971 11.357 9.97376Z"
                                                viewBox={{
                                                    minX: 0,
                                                    minY: 0,
                                                    width: 11.67401123046875,
                                                    height: 21.635986328125,
                                                }}
                                                {...getOverrideProps(overrides, "View.View[1].View[0].Icon[0]")}></Icon>
                                        </View>
                                    </Button>
                                </Flex>
                            </Grid>
                        </Grid>
                    </Grid>
                </View>
            </Flex>
        );
    } else {
        // alert("product from office is not loaded from productlist from company correctly");
        // console.log("product from office is not loaded from productlist from company correctly");
        return <div></div>;
    }
}
