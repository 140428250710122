import { Divider, Image, Text, View } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import LocalParkingOutlinedIcon from "@mui/icons-material/LocalParkingOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PriceCard from "./Cards/PriceCard";
import { OfficeWithCompanyData } from "../types/Interfaces";

const OfficeCard = ({ office }: { office: OfficeWithCompanyData }) => {
    const { t } = useTranslation();

    const getPaymentOptionText = (value: string) => {
        return t("payment-options." + value);
    };

    return (
        <Grid item xs={12}>
            <Box sx={{ flexGrow: 1 }} className="test-provider-card">
                <Grid container spacing={1}>
                    <Grid item xs={5} md={2} lg={2} className="test-provider-logo">
                        <Image src={office.companyLogo} alt={office.companyName} width="100px"></Image>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            justifyContent: {
                                xs: "left",
                                md: "center",
                            },
                        }}
                        xs={12}
                        md={8}
                        lg={8}
                        order={{ xs: 3, md: 2, lg: 2 }}>
                        <a
                            href={"https://www.google.com/maps/place/" + office.coordinates}
                            target="_blank"
                            rel="noreferrer">
                            <Text className="h3-header">
                                {t(office.officeName)}{" "}
                                {office.officeHours[0].openingTime === "" ? t("home-page.opening-soon") : ""}
                            </Text>
                        </a>
                    </Grid>
                </Grid>
                <Divider />
                <Box
                    m={2}
                    pt={2}
                    sx={{
                        flexGrow: 1,
                        display: { xs: "block", lg: "flex" },
                        justifyContent: "center",
                    }}>
                    {/*<Grid item lg={6}>*/}
                    {/*    <Grid container spacing={1}>*/}
                    {/*        <Grid item xs={2} md={1}>*/}
                    {/*            <AddLocationOutlinedIcon className="icon-dark-background"></AddLocationOutlinedIcon>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={10}>*/}
                    {/*            <View>*/}
                    {/*                <a*/}
                    {/*                    href={"https://www.google.com/maps/place/" + office.coordinates}*/}
                    {/*                    target="_blank"*/}
                    {/*                    rel="noreferrer">*/}
                    {/*                    <Text>*/}
                    {/*                        /!*{office.address}, {office.postalCode} {office.city}*!/*/}
                    {/*                        {office.officeName}*/}
                    {/*                    </Text>*/}
                    {/*                </a>*/}
                    {/*                {office.officeHours.map(({ closingTime, openingTime, day }) => (*/}
                    {/*                    <Text>*/}
                    {/*                        {openingTime} - {closingTime} ( {t("weekdays." + day)} )*/}
                    {/*                    </Text>*/}
                    {/*                ))}*/}
                    {/*            </View>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    {/*<Grid item lg={6}>*/}
                    {/*    <Grid container>*/}
                    {/*        <Grid item xs={2} md={1}>*/}
                    {/*            <AirportShuttleOutlinedIcon className="icon-dark-background"></AirportShuttleOutlinedIcon>*/}
                    {/*        </Grid>*/}

                    {/*        <View>*/}
                    {/*            <Text>*/}
                    {/*                {office.publicTransit*/}
                    {/*                    ? t("home-page.public-transportation")*/}
                    {/*                    : t("home-page.no-public-transportation")}*/}
                    {/*            </Text>*/}
                    {/*        </View>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid container>*/}
                    {/*        <Grid item xs={2} md={1}>*/}
                    {/*            <LocalParkingOutlinedIcon className="icon-dark-background"></LocalParkingOutlinedIcon>*/}
                    {/*        </Grid>*/}
                    {/*        <View>*/}
                    {/*            <Text>{office.parking ? t("home-page.parking") : t("home-page.no-parking")}</Text>*/}
                    {/*        </View>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid container>*/}
                    {/*        <Grid item xs={2} md={1}>*/}
                    {/*            <CreditCardOutlinedIcon className="icon-dark-background"></CreditCardOutlinedIcon>*/}
                    {/*        </Grid>*/}
                    {/*        <View>*/}
                    {/*            <Text>{getPaymentOptionText(office.paymentOptionCode)}</Text>*/}
                    {/*        </View>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </Box>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent={"center"}>
                        {office.productlist.map((product) => (
                            <Grid item xs={12} lg={6}>
                                <PriceCard
                                    producttype={product.producttype}
                                    companyid={office.companyId}
                                    companylogo={office.companyLogo}
                                    disabled={!product.productvalid}
                                    officeinfo={office}
                                    productobj={product}
                                    productlist={office.companyProducts}></PriceCard>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};
export default OfficeCard;
