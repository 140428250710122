import { Text } from "@aws-amplify/ui-react";
import { toLower } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import OfficeCard from "./OfficeCard";
import { OfficeWithCompanyData } from "../types/Interfaces";

const OfficesList = ({
    offices,
    searchQuery,
    filterBy,
}: {
    offices: Array<OfficeWithCompanyData>;
    searchQuery: string;
    filterBy: string;
}) => {
    const { t } = useTranslation();

    const officesWithChosenProduct = useMemo(() => {
        return filterBy === "all"
            ? offices
            : offices.filter((office) =>
                  office.productlist.some((product) =>
                      product.producttype.toLowerCase().includes(filterBy.toLowerCase())
                  )
              );
    }, [filterBy, offices]);

    let languagesetting = "-" + (localStorage.getItem("language") as string) + "-";
    if (languagesetting === "") {
        localStorage.setItem("language", "-");
        languagesetting = "-";
    }
    // language other than nl, we use en as the default.
    // if (languagesetting !== "-nl-") {
    //     languagesetting = "-en-";
    // }

    const officesWithChosenLanguageProducts = officesWithChosenProduct.map((office) => {
        return {
            ...office,
            productlist:
                languagesetting === "-nl-"
                    ? office.productlist.filter((product) =>
                          product.producttype.toLowerCase().includes(languagesetting?.toLowerCase())
                      )
                    : office.productlist.filter(
                          (product) =>
                              product.producttype.toLowerCase().includes(languagesetting?.toLowerCase()) ||
                              product.producttype.toLowerCase().includes("-en-")
                      ),
        };
    });

    const officesWithOnlyRelevantProduct = officesWithChosenLanguageProducts.map((office) => {
        return {
            ...office,
            productlist:
                filterBy === "all"
                    ? office.productlist
                    : office.productlist.filter((product) =>
                          product.producttype.toLowerCase().includes(filterBy.toLowerCase())
                      ),
        };
    });

    const officesWithActiveProducts = officesWithOnlyRelevantProduct.filter((office) => {
        return office.productlist.some((product) => product.productvalid);
    });

    const filteredOfficesWithChosenProduct =
        searchQuery === ""
            ? officesWithActiveProducts
            : officesWithActiveProducts.filter((office) => {
                  return [office.address, office.city, office.postalCode, office.officeName].some((officeProperty) =>
                      officeProperty.toLowerCase().includes(toLower(searchQuery))
                  );
              });

    return filteredOfficesWithChosenProduct.length > 0 ? (
        <>
            <Text className="h3-header" textAlign="center" fontSize={"22px"} marginBottom={24}>
                {t("home-page.test-locations")}
            </Text>

            <Grid container spacing={4}>
                {filteredOfficesWithChosenProduct.map((office) => {
                    return <OfficeCard office={office} />;
                })}
            </Grid>
        </>
    ) : (
        <Box sx={{ flexGrow: 1, my: 4 }} className="test-provider-card">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            sx={{
                                justifyContent: {
                                    xs: "center",
                                    md: "center",
                                },
                            }}
                            xs={12}
                            md={7}
                            lg={6}
                            order={{ xs: 3, md: 2, lg: 2 }}>
                            <Text className="h3-header">No results yet...</Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OfficesList;
