import React, { useContext } from "react";
import NavBar from "./NavBar";
import LoginButton from "../Buttons/LoginButton";
import { NavContext } from "../../context/Config";
import { useTranslation } from "react-i18next";
import { Button } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function AppRoutes() {
    const { t } = useTranslation();

    const { navState, setNavState } = useContext(NavContext);

    const doShowNavContent = () => {
        setNavState({ showNav: !navState.showNav });
    };

    return (
        <nav className="overlay-content">
            <NavBar showicon={false}></NavBar>
            <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        <a href="/">{t("home")}</a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        <a href="/travel-rules" onClick={doShowNavContent}>
                            {t("travel-requirements")}
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        <a href="/about-us" onClick={doShowNavContent}>
                            {t("about-us")}
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        <a href="/services" onClick={doShowNavContent}>
                            {t("services")}
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        <a href="/onboarding" onClick={doShowNavContent}>
                            {t("list-your-business")}
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        <a href="/contact-us" onClick={doShowNavContent}>
                            {t("contact-us")}
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <a href="/app/home">
                        <LoginButton className="loginButton"></LoginButton>
                    </a>
                </Grid>
                <Grid item xs={12}>
                    <a href="#test-providers">
                        <Button margin={"24px 0px"} onClick={doShowNavContent} className="third-button">
                            {t("home-page.buttons.book-test")}
                        </Button>
                    </a>
                </Grid>
            </Grid>
        </nav>
    );
}

export default AppRoutes;
