import React from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from ".";

export const useTrackPageViews = () => {
    const location = useLocation();

    React.useEffect(() => {
        trackPageView();
    }, [location]);
};
