export const routes = {
    home: {
        paths: { en: "/", fi: "/fi", cn: "/cn", nl: "/nl", jp: "/jp" },
        component: "HomePage2",
    },
    home2: {
        paths: { en: "/old", fi: "/old", cn: "/old", nl: "/old", jp: "/old" },
        component: "HomePage",
    },
    error: {
        paths: { en: "/error", fi: "/error", cn: "/error", nl: "/error", jp: "/error" },
        component: "ErrorPage",
    },
    "about-us": {
        paths: { en: "/about-us", fi: "/about-us", cn: "/about-us", nl: "/about-us", jp: "/about-us" },
        component: "AboutUsPage",
    },
    "contact-us": {
        paths: { en: "/contact-us", fi: "/contact-us", cn: "/contact-us", nl: "/contact-us", jp: "/contact-us" },
        component: "ContactUsPage",
    },
    onboarding: {
        paths: { en: "/onboarding", fi: "/onboarding", cn: "/onboarding", nl: "/onboarding", jp: "/onboarding" },
        component: "OnBoardingPage",
    },
    services: {
        paths: { en: "/services", fi: "/services", cn: "/services", nl: "/services", jp: "/services" },
        component: "ServicesPage",
    },
    "terms-and-conditions": {
        paths: {
            en: "/terms-and-conditions",
            fi: "/terms-and-conditions",
            cn: "/terms-and-conditions",
            nl: "/terms-and-conditions",
            jp: "/terms-and-conditions",
        },
        component: "TermsAndConditionsPage",
    },
    products: {
        paths: { en: "/products", fi: "/products", cn: "/products", nl: "/products", jp: "/products" },
        component: "ProductsPage",
    },
    "travel-rules": {
        paths: {
            en: "/travel-rules",
            fi: "/travel-rules",
            cn: "/travel-rules",
            nl: "/travel-rules",
            jp: "/travel-rules",
        },
        component: "TravelRulesPage",
    },
    "travel-rules/summary": {
        paths: {
            en: "/travel-rules/:from/:to",
            fi: "/travel-rules/:from/:to",
            cn: "/travel-rules/:from/:to",
            nl: "/travel-rules/:from/:to",
            jp: "/travel-rules/:from/:to",
        },
        component: "TravelRulesSummaryPage",
    },
    "book-test": {
        paths: { en: "/book-test", fi: "/book-test", cn: "/book-test", nl: "/book-test", jp: "/book-test" },
        component: "BookTestPage",
    },
    "tick-vaccine-page": {
        //old
        paths: {
            en: "/vaccinations/tbe",
            fi: "/fi/punkkirokotus",
            cn: "/cn/vaccinations/tbe",
            nl: "/nl/vaccinations/tbe",
            jp: "/jp/vaccinations/tbe",
        },
        component: "TickVaccinePage",
    },
    "travel-vaccine-page": {
        //new
        paths: {
            en: "/en/travelvaccine",
            fi: "/fi/matkarokotteet",
            cn: "/cn/travelvaccine",
            nl: "/nl/reisvaccinatie",
            jp: "/jp/travelvaccine",
        },
        component: "TravelVaccinePage",
    },
    "seasonal-vaccine-page": {
        paths: {
            en: "/en/seasonalvaccine",
            fi: "/fi/seasonalvaccine",
            cn: "/cn/seasonalvaccine",
            nl: "/nl/seizoensvaccinatie",
            jp: "/jp/seasonalvaccine",
        },
        component: "SeasonalVaccinePage",
    },
    "COVID-19-tests-page": {
        paths: {
            en: "/en/covid19tests",
            fi: "/fi/covid19tests",
            cn: "/cn/covid19tests",
            nl: "/nl/covid-test",
            jp: "/jp/covid19tests",
        },
        component: "COVID19TestsPage",
    },
    "STD-tests-page": {
        paths: {
            en: "/en/stdtests",
            fi: "/fi/stdtests",
            cn: "/cn/stdtests",
            nl: "/nl/soa-test",
            jp: "/jp/stdtests",
        },
        component: "STDTestsPage",
    },
    "doctor-appointment-page": {
        paths: {
            en: "/en/doctorappointment",
            fi: "/fi/doctorappointment",
            cn: "/cn/doctorappointment",
            nl: "/nl/doktersafspraak",
            jp: "/jp/doctorappointment",
        },
        component: "DoctorAppointmentPage",
    },

    "vaccines-base-page": {
        //old
        paths: {
            en: "en/vaccinations",
            fi: "fi/rokotteet",
            cn: "cn/vaccinations",
            nl: "nl/vaccinations",
            jp: "jp/vaccinations",
        },
        component: "VaccinesPage",
    },
    "vaccines-page": {
        // new
        paths: {
            en: "en/vaccinations/:vaccine",
            fi: "fi/rokotteet/:vaccine",
            cn: "cn/vaccinations/:vaccine",
            nl: "nl/vaccinations/:vaccine",
            jp: "jp/vaccinations/:vaccine",
        },
        component: "VaccinesPage",
    },

    "booking-landing-page": {
        //booking landing page
        paths: { en: "/en/booking", fi: "/fi/booking", cn: "/cn/booking", nl: "/nl/booking", jp: "/jp/booking" },
        component: "BookingLandingPage",
    },
    "choose-people": {
        paths: {
            en: "/choose-people",
            fi: "/choose-people",
            cn: "/choose-people",
            nl: "/choose-people",
            jp: "/choose-people",
        },
        component: "ChoosePeoplePage",
    },
    "choose-timeslot": {
        paths: {
            en: "/choose-timeslot",
            fi: "/choose-timeslot",
            cn: "/choose-timeslot",
            nl: "/choose-timeslot",
            jp: "/choose-timeslot",
        },
        component: "TimeSlotPage",
    },
    "customer-details": {
        paths: {
            en: "/customer-details/:id",
            fi: "/customer-details/:id",
            cn: "/customer-details/:id",
            nl: "/customer-details/:id",
            jp: "/customer-details/:id",
        },
        component: "CustomerDetailsPage",
    },
    "sign-up": {
        paths: { en: "/sign-up", fi: "/sign-up", cn: "/sign-up", nl: "/sign-up", jp: "/sign-up" },
        component: "SignUpPage",
    },
    confirm: {
        paths: { en: "/confirm", fi: "/confirm", cn: "/confirm", nl: "/confirm", jp: "/confirm" },
        component: "ConfirmPage",
    },
    payment: {
        paths: { en: "/payment", fi: "/payment", cn: "/payment", nl: "/payment", jp: "/payment" },
        component: "PaymentPage",
    },
    "payment-succeed": {
        paths: {
            en: "/payment-succeed",
            fi: "/payment-succeed",
            cn: "/payment-succeed",
            nl: "/payment-succeed",
            jp: "/payment-succeed",
        },
        component: "PaymentSucceedPage",
    },
    terms: {
        paths: { en: "/terms", fi: "/terms", cn: "/terms", nl: "/terms", jp: "/terms" },
        component: "TermsPage",
    },
    "privacy-policy": {
        paths: {
            en: "/privacy-policy",
            fi: "/privacy-policy",
            cn: "/privacy-policy",
            nl: "/privacy-policy",
            jp: "/privacy-policy",
        },
        component: "PrivacyPolicyPage",
    },
};
export const privateRoutes = {
    home: {
        paths: { en: "/home", fi: "/home", cn: "/home", nl: "/home", jp: "/home" },
        component: "PrivateHomePage",
    },
    "log-out": {
        paths: { en: "/log-out", fi: "/log-out", cn: "/log-out", nl: "/log-out", jp: "/log-out" },
        component: "LogoutPage",
    },
    booking: {
        paths: { en: "/booking", fi: "/booking", cn: "/booking", nl: "/booking", jp: "/booking" },
        component: "PrivateBookingPage",
    },
    results: {
        paths: { en: "/results", fi: "/results", cn: "/results", nl: "/results", jp: "/results" },
        component: "PrivateResultsPage",
    },
    airport: {
        paths: { en: "/airport", fi: "/airport", cn: "/airport", nl: "/airport", jp: "/airport" },
        component: "PrivateAirportPage",
    },
    "qr-codes": {
        paths: { en: "/qr-codes", fi: "/qr-codes", cn: "/qr-codes", nl: "/qr-codes", jp: "/qr-codes" },
        component: "PrivateQRCodesPage",
    },
    "view-details": {
        paths: {
            en: "/view-details/:id",
            fi: "/view-details/:id",
            cn: "/view-details/:id",
            nl: "/view-details/:id",
            jp: "/view-details/:id",
        },
        component: "PrivateViewDetailsPage",
    },
    "check-in": {
        paths: { en: "/check-in", fi: "/check-in", cn: "/check-in", nl: "/check-in", jp: "/check-in" },
        component: "PrivateCheckInPage",
    },
    "check-in-confirmed": {
        paths: {
            en: "/check-in-confirmed",
            fi: "/check-in-confirmed",
            cn: "/check-in-confirmed",
            nl: "/check-in-confirmed",
            jp: "/check-in-confirmed",
        },
        component: "PrivateCheckInConfirmedPage",
    },
    "come-prepared": {
        paths: {
            en: "/come-prepared",
            fi: "/come-prepared",
            cn: "/come-prepared",
            nl: "/come-prepared",
            jp: "/come-prepared",
        },
        component: "PrivatePreparePage",
    },
    "airport-info": {
        paths: {
            en: "/airport-info",
            fi: "/airport-info",
            cn: "/airport-info",
            nl: "/airport-info",
            jp: "/airport-info",
        },
        component: "PrivateAirportInfoPage",
    },
    "pcr-info": {
        paths: { en: "/pcr-info", fi: "/pcr-info", cn: "/pcr-info", nl: "/pcr-info", jp: "/pcr-info" },
        component: "PrivatePcrInfoPage",
    },
    profile: {
        paths: { en: "/profile", fi: "/profile", cn: "/profile", nl: "/profile", jp: "/profile" },
        component: "PrivateProfilePage",
    },
};
