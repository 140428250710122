import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import * as themes from "./theme/schema.json";
import { setToLS } from "./utils/storage";
import "@aws-amplify/ui-react/styles.css";

const Index = () => {
    const themesObj: any = themes;
    setToLS("all-themes", themesObj);
    return <App />;
};

ReactDOM.render(<Index />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
