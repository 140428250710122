import { useEffect, useState } from "react";
import { getFromLS, setToLS } from "../utils/storage";
import _ from "lodash";

export const useTheme = () => {
    const path = process.env.REACT_APP_CLIENT_NAME;
    const themes = getFromLS("all-themes");
    const [theme, setTheme] = useState(themes.data.light);
    const [themeLoaded, setThemeLoaded] = useState(false);

    const setMode = (mode: any) => {
        setToLS("theme", mode);
        setTheme(mode);
    };

    const getFonts = () => {
        const allFonts = _.values(_.mapValues(themes.data, "font"));
        return allFonts;
    };

    useEffect(() => {
        const themePath = path && themes.data[path] ? themes.data[path] : themes.data.light;
        const localTheme = getFromLS("theme");
        localTheme ? setTheme(localTheme) : setTheme(themePath);
        setThemeLoaded(true);
    }, []);

    return { theme, themeLoaded, setMode, getFonts };
};
