import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Image } from "@aws-amplify/ui-react";

export default function ImageContainer(props: any) {
    const { overrides: overridesProp, ...rest } = props;
    const overrides = { ...overridesProp };
    const img = props.img;
    return (
        <Image
            className="image-container"
            alt="Logo"
            height="auto"
            src={img}
            color="rgba(20.35486936569214,154.60028260946274,251.81250303983688,1)"
            shrink="0"
            position="relative"
            ariaLabel=""
            {...getOverrideProps(overrides, "Flex.Flex[0].Icon[0]")}></Image>
    );
}
