import { Button, Icon, Text, View } from "@aws-amplify/ui-react";
import _, { orderBy } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import FilterDestinationInput from "../Inputs/FilterDestinationInput";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { Company, OfficeWithCompanyData } from "../../types/Interfaces";
import OfficesList from "../OfficesList";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import { getServiceTypeItemsArray } from "../select/groupselect";

const locationMarker = L.icon({
    iconUrl: require("./../../assets/img/location-marker.png"),
    iconSize: [30, 30],
});

const productOptions = [
    {
        value: "all",
        text: "all",
    },
    {
        value: "pcr",
        text: "PCR",
    },
    {
        value: "antigen",
        text: "antigen",
    },
    {
        value: "vaccine",
        text: "vaccine",
    },
    {
        value: "hepatitis",
        text: "hepatitis",
    },
    {
        value: "influenza",
        text: "influenza",
    },
];

const OfficeContent = (props: any) => {
    const { t } = useTranslation();
    const companies = props.companies;

    const [searchkey, setsearchkey] = useState("");
    const [filterkey, setfilterkey] = useState(props.filterkeydefault ? (props.filterkeydefault as string) : "all");
    const [countryfilter, setcountryfilter] = useState(props.countryfilter ? (props.countryfilter as string) : "all");
    const changefilterbutton = (event: any) => {
        setfilterkey(event.target.value);
    };
    const changefilterbuttongroup = (event: any) => {
        setfilterkey(event);
    };

    const handleSearchKeyChange = (event: any) => {
        // console.log("event target");
        // console.log(event.target.value);
        setsearchkey(event.target.value);
    };

    const handleSearchKeyChangeButton = (event: any) => {
        // console.log("event target button");
        // console.log(event.target.value);
        // setsearchkey(event.target.value);
    };

    const sortedOfficesList = orderBy(companies, ["listorder"], ["asc"]).flatMap(
        (company: Company): OfficeWithCompanyData[] => {
            return company.offices.map((office) => {
                return {
                    ...office,
                    companyName: company.companyName,
                    companyId: company.id,
                    companyLogo: company.logo,
                    companyProducts: company.products,
                    country: company.country,
                };
            });
        }
    );

    const officesWithCountryFilter = sortedOfficesList.filter((office) => {
        if (countryfilter === "all") {
            return true;
        } else {
            return office.country.toLowerCase().includes(countryfilter.toLowerCase());
        }
    });

    const productListItems = getServiceTypeItemsArray(
        sessionStorage.getItem("ParentServiceType") as string,
        sessionStorage.getItem("ServiceType") as string
    );

    //map related
    function findmapbounds(officelist) {
        const mapbounds = [] as any[];
        _.forEach(officelist, (officeobj) => {
            const mapcoordinateobj = officeobj.coordinates.split(", ", 2);
            if (mapcoordinateobj.length === 2) {
                mapbounds.push(mapcoordinateobj);
            }
        });
        //find the bounds of the map
        const mapboundsobj = L.latLngBounds(mapbounds);
        return mapboundsobj;
    }

    function mappins(officelist) {
        const mapmarkers = [] as JSX.Element[];

        _.forEach(officelist, (officeobj) => {
            const mapcoordinateobj = officeobj.coordinates.split(", ", 2);
            if (mapcoordinateobj.length === 2) {
                mapmarkers.push(
                    <Marker position={mapcoordinateobj} icon={locationMarker}>
                        <Popup>
                            <Text className={"text-main-title2"}>{officeobj.officeName}</Text>
                            <a href="#officelistview">
                                <View content="center" display="flex" justifyContent="center" alignItems="center">
                                    <Button
                                        display="flex"
                                        id={"product"}
                                        className="primary-button"
                                        onClick={() => {
                                            // setSearchKeyDefaultval(office.officeName);
                                            // searchkeydefaultval = office.officeName;
                                            // console.log(searchkeydefaultval);
                                            setsearchkey(officeobj.officeName);
                                            console.log(searchkey);
                                        }}
                                        justifyContent="center"
                                        padding="10px 15px"
                                        margin="10px 15px"
                                        alignItems="center"
                                        border="0px"
                                        width="160px"
                                        maxHeight={"40px"}
                                        minHeight={"30px"}
                                        // disabled={""}
                                    >
                                        <Text
                                            id={"product"}
                                            className="vaccine-box-texts"
                                            fontSize="14px"
                                            fontWeight="900"
                                            lineHeight="20px"
                                            textAlign="center"
                                            // display="flex"
                                            width="70px"
                                            position=""
                                            left="243px"
                                            top="30px"
                                            padding="0px 0px 0px 0px"
                                            children={t("choose-location")}></Text>
                                        <View height="24px">
                                            <Icon
                                                id={"product"}
                                                className="vaccine-box-texts"
                                                ariaLabel=""
                                                width="11px"
                                                height="14px"
                                                pathData="M11.357 9.97376L2.51422 0.345645C2.3097 0.122779 2.03667 0 1.74556 0C1.45444 0 1.18142 0.122779 0.9769 0.345645L0.325687 1.05453C-0.0980614 1.51644 -0.0980614 2.26719 0.325687 2.7284L7.75122 10.8135L0.317448 18.9076C0.112924 19.1305 0 19.4275 0 19.7443C0 20.0615 0.112924 20.3586 0.317448 20.5816L0.96866 21.2903C1.17335 21.5132 1.44621 21.636 1.73732 21.636C2.02844 21.636 2.30146 21.5132 2.50598 21.2903L11.357 11.6534C11.5621 11.4299 11.6747 11.1314 11.674 10.814C11.6747 10.4955 11.5621 10.1971 11.357 9.97376Z"
                                                viewBox={{
                                                    minX: 0,
                                                    minY: 0,
                                                    width: 11.67401123046875,
                                                    height: 21.635986328125,
                                                }}></Icon>
                                        </View>
                                    </Button>
                                </View>
                            </a>
                        </Popup>
                    </Marker>
                );
            }
        });
        return mapmarkers;
    }

    return (
        <>
            <View id="mapid-home" padding={"0px 0px"}>
                {/*<MapContainer center={[54.03, 19.99]} zoom={4.2} height="100%">*/}
                <MapContainer bounds={findmapbounds(officesWithCountryFilter)} scrollWheelZoom={false} height="100%">
                    <TileLayer attribution="" url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                    {mappins(officesWithCountryFilter)}
                </MapContainer>
            </View>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={9} lg={6} xl={4} id="what-do-we-do" alignItems="center">
                    <FilterDestinationInput
                        icon={<LanguageOutlinedIcon />}
                        text={t("travel-rules-summary.traveling-from")}
                        onChange={handleSearchKeyChange}
                    />
                </Grid>
                <Grid item xs={3} lg={1} xl={1} id="what-do-we-do" alignItems="center">
                    <a href="#officelistview">
                        <Button
                            display="flex"
                            id={"product"}
                            className="pointer primary-button-sm"
                            onClick={handleSearchKeyChangeButton}
                            justifyContent="center"
                            padding="10px 15px"
                            margin="10px 15px"
                            alignItems="center"
                            border="0px"
                            width="100%"
                            maxHeight={"100px"}
                            minHeight={"30px"}
                            // disabled={""}
                        >
                            <Text
                                id={"product"}
                                className="vaccine-box-texts"
                                fontSize="14px"
                                fontWeight="900"
                                lineHeight="20px"
                                textAlign="center"
                                // display="flex"
                                width="70px"
                                position=""
                                left="243px"
                                top="30px"
                                padding="0px 0px 0px 0px"
                                children={t("home-page2.search-bar-groupselect-find-button")}></Text>
                            <View height="24px">
                                <Icon
                                    id={"product"}
                                    className="vaccine-box-texts"
                                    ariaLabel=""
                                    width="11px"
                                    height="14px"
                                    pathData="M11.357 9.97376L2.51422 0.345645C2.3097 0.122779 2.03667 0 1.74556 0C1.45444 0 1.18142 0.122779 0.9769 0.345645L0.325687 1.05453C-0.0980614 1.51644 -0.0980614 2.26719 0.325687 2.7284L7.75122 10.8135L0.317448 18.9076C0.112924 19.1305 0 19.4275 0 19.7443C0 20.0615 0.112924 20.3586 0.317448 20.5816L0.96866 21.2903C1.17335 21.5132 1.44621 21.636 1.73732 21.636C2.02844 21.636 2.30146 21.5132 2.50598 21.2903L11.357 11.6534C11.5621 11.4299 11.6747 11.1314 11.674 10.814C11.6747 10.4955 11.5621 10.1971 11.357 9.97376Z"
                                    viewBox={{
                                        minX: 0,
                                        minY: 0,
                                        width: 11.67401123046875,
                                        height: 21.635986328125,
                                    }}></Icon>
                            </View>
                        </Button>
                    </a>
                </Grid>
            </Grid>
            {/*<Grid container spacing={2} alignItems="center" justifyContent="center">*/}
            {/*<Grid item xs={12} md={8}>*/}
            {/*    {props.filterdisabled !== "true" ? (*/}
            {/*        <ToggleButtonGroup*/}
            {/*            color="primary"*/}
            {/*            fullWidth*/}
            {/*            exclusive*/}
            {/*            sx={(theme) => ({*/}
            {/*                display: "grid",*/}
            {/*                gridTemplateColumns: "repeat(4, 1fr)",*/}
            {/*                gap: theme.spacing(1),*/}
            {/*                marginBottom: theme.spacing(4),*/}
            {/*                "& .MuiToggleButtonGroup-grouped:not(:last-of-type), & .MuiToggleButtonGroup-grouped:not(:first-of-type)":*/}
            {/*                    {*/}
            {/*                        borderRadius: theme.spacing(3.75),*/}
            {/*                        borderColor: "transparent",*/}
            {/*                    },*/}
            {/*                "& .MuiToggleButton-root": {*/}
            {/*                    textTransform: "none",*/}
            {/*                    borderRadius: theme.shape.borderRadius,*/}
            {/*                    fontSize: theme.spacing(2.25),*/}
            {/*                    padding: theme.spacing(1.25),*/}
            {/*                    backgroundColor: theme.palette.common.white,*/}
            {/*                    boxShadow: theme.shadows[1],*/}
            {/*                    "&.Mui-selected": {*/}
            {/*                        backgroundColor: theme.palette.primary.light,*/}
            {/*                        color: theme.palette.primary.contrastText,*/}
            {/*                        "&:hover": {*/}
            {/*                            backgroundColor: theme.palette.primary.main,*/}
            {/*                        },*/}
            {/*                    },*/}
            {/*                    "&:hover": {*/}
            {/*                        backgroundColor: theme.palette.action.hover,*/}
            {/*                    },*/}
            {/*                },*/}
            {/*            })}*/}
            {/*            value={filterkey}*/}
            {/*            onChange={(e) => {*/}
            {/*                changefilterbutton(e);*/}
            {/*            }}>*/}
            {/*            {productOptions.map((option) => (*/}
            {/*                <ToggleButton key={option.value} value={option.value}>*/}
            {/*                    {t(option.text)}*/}
            {/*                </ToggleButton>*/}
            {/*            ))}*/}
            {/*        </ToggleButtonGroup>*/}
            {/*    ) : (*/}
            {/*        ""*/}
            {/*    )}*/}
            {/*</Grid>*/}
            {/*</Grid>*/}
            <View className="homepage-session">
                <Grid container spacing={2} justifyContent={"center"}>
                    {/*<Grid item xs={10}>*/}
                    {/*    <div className="homepage-frame-session-text-session">*/}
                    {/*        <div className="homepage-frame-session-title">*/}
                    {/*            <Text className="homepage-session-title-text">*/}
                    {/*                {t("travel-vaccine-page.vaccine-offer-title")}*/}
                    {/*            </Text>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}
                    <Grid item xs={10}>
                        <Grid container justifyContent={"center"} direction={"row"}>
                            {productListItems?.map((item, index) => (
                                <Grid item xs="auto">
                                    <div style={{ padding: "10px" }}>
                                        <Button
                                            width="100%"
                                            onClick={() => {
                                                sessionStorage.setItem("ProductGroup", item.ProductGroupValue);
                                                changefilterbuttongroup(item.ProductGroupValue);
                                                // navigate(t("public-links.booking-landing-page"));
                                            }}
                                            className="primary-button-blue">
                                            {/*{item.ProductGroupName}*/}
                                            {t("grouppages." + item.ProductGroupValue)}
                                        </Button>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </View>
            <View id="officelistview">
                <OfficesList offices={officesWithCountryFilter} filterBy={filterkey} searchQuery={searchkey} />
            </View>
        </>
    );
};

export default OfficeContent;
