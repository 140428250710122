import React, { useEffect, useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import NavBar from "./NavBar";
import DesktopNavBar from "./DesktopNavBar";

const ReactiveNavBar = () => {
    return (
        <>
            <DesktopNavBar showicon={true} className="desktopNavBar"></DesktopNavBar>
            <NavBar showicon={true} className="mobileNavBar"></NavBar>
            <Flex height={"20px"}></Flex>
        </>
    );
};
export default ReactiveNavBar;
