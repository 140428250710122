// client request: creating a new todo
import { API, Auth } from "aws-amplify";
import { ICustomerData, IOrderData } from "../types/Interfaces";
import logger from "../logger";

const apiPath = "avc-api";

async function getAllOrders() {
    // const user = await Auth.currentSession();
    // const token = user.getIdToken().getJwtToken();

    //specify the order to the correct table
    const ordertablename = sessionStorage.getItem("companyid");

    return await API.get(apiPath, "/orders-by-table/" + ordertablename + "OrdersTable", {
        headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.REACT_APP_ORDERSAPI_KEY,
        },
    })
        .then((result) => {
            try {
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse getAllOrders response: " + error);
                //console.error('Failed to parse getAllOrders response: ' + error);
            }
        })
        .catch((err) => {
            logger.error("Failed to fetch getAllOrders: " + err);
            //console.log('Failed to fetch getAllOrders: ' + err);
        });
}

async function getCustomerOrders(id) {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    return await API.get(apiPath, `/orders/${id}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((result) => {
            try {
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse getCustomerOrders response: " + error);
                //console.error('Failed to parse getCustomerOrders response: ' + error);
            }
        })
        .catch((err) => {
            logger.error("Failed to fetch getCustomerOrders: " + err);
            //console.log('Failed to fetch getCustomerOrders: ' + err);
        });
}

async function postOrder(contextOrder: IOrderData, userName: string) {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    //specify the order to the correct table
    const ordertablename = sessionStorage.getItem("companyid");

    //Call API Name / path / request
    return await API.post(apiPath, "/orders/" + ordertablename + "OrdersTable", {
        //todo add table name here from the selected product
        body: {
            userName: userName,
            orders: contextOrder.orders,
            location: contextOrder.location,
            bookingDate: contextOrder.bookingDate,
            isPaid: contextOrder.isPaid,
            status: contextOrder.status,
            iata: contextOrder.iata,
            producttype: contextOrder.producttype,
            productquatity: contextOrder.productquatity,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((result) => {
            try {
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse postOrder response: " + error);
                //console.error('Failed to parse postOrder response: ' + error)
            }
        })
        .catch((err) => {
            logger.error("Failed to post postOrder: " + err);
            //console.log('Failed to post postOrder: ' + err);
        });
}

async function putOrderStatus(status: string, id: string, createdAt: Date | null) {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    //Call API Name / path / request
    return await API.put(apiPath, "/orders-status", {
        body: {
            id: id,
            createdAt: createdAt,
            status: status,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((result) => {
            try {
                logger.info(result);
                //console.log(result);
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse putOrderStatus response: " + error);
                //console.error('Failed to parse putOrderStatus response: ' + error)
            }
        })
        .catch((err) => {
            logger.error("Failed to put putOrderStatus: " + err);
            //console.log('Failed to put putOrderStatus: ' + err);
        });
}

async function putOrder(params: ICustomerData[], id: string, createdAt: Date | null) {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    //Call API Name / path / request
    return await API.put(apiPath, "/orders", {
        body: {
            id: id,
            createdAt: createdAt,
            orders: params,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((result) => {
            try {
                return JSON.parse(result.body);
            } catch (error) {
                logger.error("Failed to parse putOrder response: " + error);
                //console.error('Failed to parse putOrder response: ' + error)
            }
        })
        .catch((err) => {
            logger.error("Failed to put putOrder: " + err);
            //console.log('Failed to put putOrder: ' + err);
        });
}

export { postOrder, getAllOrders, getCustomerOrders, putOrder, putOrderStatus };
