import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, Text, View } from "@aws-amplify/ui-react";

export default function Navigationunselected(props: any) {
    const { overrides: overridesProp, ...rest } = props;
    const overrides = { ...overridesProp };
    const number = props.number;
    const text = props.text;

    return (
        <View
            position="relative"
            padding="0px 0px 0px 0px"
            width="100%"
            height="30px"
            {...rest}
            {...getOverrideProps(overrides, "View")}>
            <Icon
                color="rgba(255,255,255,1)"
                ariaLabel=""
                width="28px"
                height="28px"
                pathData="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z"
                viewBox={{ minX: 0, minY: 0, width: 28, height: 28 }}
                position="absolute"
                left="0px"
                top="0px"
                border="1px solid #CCCCCC"
                borderRadius="50%"
                {...getOverrideProps(overrides, "View.Icon[0]")}></Icon>
            <Text
                className="service-steps"
                fontSize="18px"
                fontWeight="700"
                lineHeight="17.666667938232422px"
                textAlign="left"
                display="flex"
                position="absolute"
                left="9px"
                top="5px"
                padding="0px 0px 0px 0px"
                children={number}
                {...getOverrideProps(overrides, "View.Text[0]")}></Text>
        </View>
    );
}
