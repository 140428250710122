import React, { useEffect, useContext, useState } from "react";
import { View } from "@aws-amplify/ui-react";
import CountryFlag from "../Images/CountryFlag";
import i18n from "../../utils/i18n";
import _ from "lodash";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const actions = [
    {
        icon: <CountryFlag code={"GB"}></CountryFlag>,
        country: "GB",
        lang: "EN",
    },
    {
        icon: <CountryFlag code={"FI"}></CountryFlag>,
        country: "FI",
        lang: "FI",
    },
    {
        icon: <CountryFlag code={"CN"}></CountryFlag>,
        country: "CN",
        lang: "CN",
    },
    {
        icon: <CountryFlag code={"NL"}></CountryFlag>,
        country: "NL",
        lang: "NL",
    },
    {
        icon: <CountryFlag code={"JP"}></CountryFlag>,
        country: "JP",
        lang: "JP",
    },
];

export default function NavBar(props: any) {
    const { t, i18n } = useTranslation();

    const { overrides: overridesProp, showicon, ...rest } = props;
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const currentLanguage = i18n.language.toLocaleUpperCase();
    const currentCountryObj = actions.find((action) => {
        return action.lang == currentLanguage;
    });
    const currentCountry = currentCountryObj ? currentCountryObj.country : "GB";

    const [selectedCountry, setSelectedCountry] = React.useState(currentCountry);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    useEffect(() => {
        const newLink = i18n.getDataByLanguage("fi")?.translation["public-links"];
    }, []);

    const handleCloseUserMenu = (country, lang) => {
        setAnchorElUser(null);
        if (country && _.isString(country)) {
            const currentLinkTranslation = Object.entries(
                i18n.getDataByLanguage(i18n.language)?.translation["public-links"] as unknown as Record<string, string>
            ).find(([_, currentLink]) => {
                const pathnamesegment = pathname.split("/");
                if (pathnamesegment.length > 2) {
                    return currentLink.includes(pathname.split("/")[1] + "/" + pathname.split("/")[2]);
                } else {
                    return currentLink.includes(pathname);
                }
            });
            if (currentLinkTranslation) {
                const [langKey] = currentLinkTranslation;
                const newLink = t(`public-links.${langKey}`, {
                    lng: lang.toLowerCase(),
                });
                navigate(newLink);
            }
            i18n.changeLanguage(lang.toLowerCase());
            setSelectedCountry(country);

            localStorage.setItem("language", lang.toLowerCase());
        }
    };

    return (
        <View>
            <Tooltip title="Change language" style={{ backgroundColor: "white" }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <CountryFlag code={selectedCountry} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {actions.map((action) => (
                    <MenuItem
                        style={{ backgroundColor: "none" }}
                        key={action.country}
                        onClick={() => {
                            handleCloseUserMenu(action.country, action.lang);
                        }}>
                        <Typography textAlign="center">{action.lang}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </View>
    );
}
