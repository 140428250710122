import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Text, View } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const Accordion = styled((props) => <MuiAccordion children={""} disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        borderRadius: "4px",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    })
);

export default function SimpleAccordion(props: any) {
    const { t } = useTranslation();
    const text = props.text ? props.text : "";
    const title = props.title;

    return (
        <View>
            <Accordion sx={{ margin: "24px 0px" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Text fontSize="14px" fontWeight={"bold"} letterSpacing="0.01em" lineHeight="18px">
                        {title}
                    </Text>
                </AccordionSummary>
                <AccordionDetails>
                    <Text>{text}</Text>
                </AccordionDetails>
            </Accordion>
        </View>
    );
}
