import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import ReactLoading from "react-loading";

const SpinLoader = (props: any) => {
    const widthValue = props.width ? props.width : 300;
    const heightValue = props.height ? props.height : 300;
    return (
        <Flex height="100%" justifyContent="center" alignItems="center">
            <ReactLoading type={"spin"} color={"blue"} height={heightValue} width={widthValue} />
        </Flex>
    );
};

export default SpinLoader;
