import { useContext } from "react";
import { ConfigContext } from "../context/Config";
import { Text, Image, View } from "@aws-amplify/ui-react";
import ServiceSteps from "./Navigation/ServiceSteps";
import Grid from "@mui/material/Grid";

function TextImageContainer({
    title,
    description,
    img,
    showBorder,
    number,
}: {
    title: string;
    description: string;
    img: string;
    showBorder: boolean;
    number: number;
}) {
    const data = useContext(ConfigContext);
    const card = data?.components?.card;
    const borderClass = showBorder ? "border-left" : "";

    return (
        <View position="relative" height="auto" padding="0" margin="0">
            <Grid container>
                <Grid item xs={1} padding="30px 0px 0px 15px">
                    <ServiceSteps className="step-circle" number={number} text={title}></ServiceSteps>
                </Grid>
                <Grid item xs={5}>
                    <Grid item xs={12} padding="16px 0px">
                        <Text
                            className="service-steps"
                            fontSize="18px"
                            fontWeight="600"
                            maxWidth={"150px"}
                            lineHeight="17.666667938232422px"
                            textAlign="left"
                            children={title}></Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Text className="padding-sm" maxWidth={"150px"}>
                            {description}
                        </Text>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Image
                        alt="Certificate"
                        src={img}
                        maxWidth="180px"
                        margin={"10px 30px 0px 0px"}
                        height="auto"></Image>
                </Grid>
            </Grid>
        </View>
    );
}

export default TextImageContainer;
