export const config = {
    layout_type: 1,
    clientId: 1,
    name: "aava",
    components: {
        card: {
            showHeader: true,
            showImage: true,
            showFooter: true,
        },
    },
    themes: {
        theme: {
            name: "aava",
        },
    },
    locales: ["en", "fi", "cn", "nl", "jp"],
};
